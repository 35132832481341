import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer/index.js";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store/index.js";
import StarRating from "../../components/StarRating.js";
import Select from "react-select";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  ListGroup,
  Table,
  Modal,
} from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md";
import config from "../../services/apiConfig.js";
import { isEmpty } from "./utils.js";
import WTTImage from "../../assets/img/EVENT_REPORT.png";
import { Bar, Pie } from "react-chartjs-2";
import { apiPaths } from "../../services/apiPath.js";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { blue } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import InventoryIcon from "@mui/icons-material/Inventory";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import InsightsIcon from "@mui/icons-material/Insights";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import PaymentsIcon from "@mui/icons-material/Payments";
import FunctionsIcon from "@mui/icons-material/Functions";
import ContactlessIcon from "@mui/icons-material/Contactless";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FeedbackIcon from "@mui/icons-material/Feedback";
import InstagramIcon from "@mui/icons-material/Instagram";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../ErrorFallBackScreen/index.js";
import TableData from "../../components/UI/ItemsSoldTable/index.js";
import SocialMediaModal from "./SocialMediaModal";
import MarketingConclusionModal from "./MarketingConclusionModal";
import Chart from "chart.js/auto";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tooltip as MuiTooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import WeatherForecastModal from "../OperatorPostEventFeedbackScreen/WeatherForecastModal.js";
import AdminNavBar from "../../components/UI/AdminNavBar/index.js";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  section: {
    margin: 10,
    padding: 30,
  },
  header: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: "bold",
  },
  content: {
    fontSize: 12,
    marginBottom: 5,
  },
  contentQuestion: {
    fontSize: 13,
    marginBottom: 5,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginLeft: "20px",
    marginRight: "20px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    margin: "auto",
    flex: 1,
    flexGrow: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 10,
  },
  headerCell: {
    fontWeight: "bolder",
    fontSize: 12,
  },
});
const styles1 = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },
  detailsSection: {
    marginBottom: 30,
  },
  detail: {
    fontSize: 16,
    marginBottom: 5,
  },
  detailValue: {
    fontWeight: "bold",
  },
  tocSection: {
    marginTop: 20,
  },
  tocTitle: {
    fontSize: 20,
    marginBottom: 10,
  },
  tocItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  tocText: {
    fontSize: 16,
  },
  tocPage: {
    fontSize: 16,
  },
  contentSection: {
    alignItems: "center",
    padding: 20, // Add some padding around the content
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
    fontFamily: "Helvetica",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `2px solid #3959a5`,
    paddingBottom: 10,
  },
  salesReport: {
    fontSize: 24,
    color: "#3959a5",
    fontWeight: "bold",
  },
  contentSection: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
  iconAndText: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
    display: "flex",
    justifyContent: "flex-start",
  },
  contentText: {
    fontSize: 14,
    marginLeft: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 24,
    bottom: 25,
    right: 50,
    color: "#3959a5",
  },
  footer: {
    borderTop: `2px solid #3959a5`,
    position: "absolute",
    bottom: 65,
    left: 40,
    right: 40,
  },
});

const EventReport = ({
  data,
  feedbackData,
  ratings,
  peak_food_time_hour,
  average_spend,
  total_event_sales,
  total_sales_for_peak_hour,
  total_stock_purchased,
  payoutDetails,
  forecastData,
  selectedVendorName,
  selectedEventName,
  start_date,
  end_date,
}) => {
  console.log(start_date, end_date);
  const renderRatings = () => {
    if (!ratings || ratings.length === 0) {
      return <Text>No Ratings Available</Text>;
    }
    return ratings.map((rating, index) => (
      <View key={index} style={styles.section}>
        <Text style={styles.content}>
          {"Rating: " + (Number(rating.ratings_value) / 2).toFixed(1) + "/5"}
        </Text>
        <Text style={styles.content}>{"Comment: " + rating.comments}</Text>
      </View>
    ));
  };
  const renderFeedbackData = () => {
    if (!feedbackData || feedbackData.length === 0) {
      return <Text>No Feedback Data Available</Text>;
    }

    return feedbackData.map((feedback, index) => (
      <View key={index} style={styles.section}>
        <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
          Feedback from {feedback.time_stamp}
        </Text>
        {feedback.feedback_lists.map((item, idx) => (
          <View key={idx} style={styles.table}>
            <Text style={styles.contentQuestion}>{item.feedback_question}</Text>
            <Text style={styles.content}>{item.feedback_answer}</Text>
          </View>
        ))}
      </View>
    ));
  };
  const renderCostByDayTable = () => {
    if (!data.cost_by_day || Object.keys(data.cost_by_day).length === 0) {
      return <Text>No Shift Data Available</Text>;
    }

    return Object.entries(data.cost_by_day).map(([date, details]) => (
      <View key={date} wrap={false}>
        {/* Heading for each day */}
        <Text
          style={[
            styles.subHeader,
            { textAlign: "center", paddingTop: "10px" },
          ]}
        >
          {moment(date).format("DD-MM-YYYY")}
        </Text>

        <View
          style={[styles.table, { paddingTop: "10px", paddingBottom: "20px" }]}
        >
          {/* Table headers */}
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.headerCell]}>User</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Total Cost
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Avg Cost/Hr
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Hours Worked
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Cost</Text>
          </View>

          {/* Table rows for each user */}
          {Object.keys(details.user_costs).map((user) => (
            <View key={user} style={styles.tableRow}>
              <Text style={styles.tableCell}>{user}</Text>
              <Text style={styles.tableCell}>
                {details.total_cost.toFixed(2)}
              </Text>
              <Text style={styles.tableCell}>
                {details.avg_cost_per_hour[user].toFixed(2)}
              </Text>
              <Text style={styles.tableCell}>
                {details.hours_worked[user].toFixed(2)}
              </Text>
              <Text style={styles.tableCell}>
                {details.user_costs[user].toFixed(2)}
              </Text>
            </View>
          ))}
        </View>
      </View>
    ));
  };

  const renderCostByHourTable = () => {
    if (
      !data.sorted_hourly_cost ||
      Object.keys(data.sorted_hourly_cost).length === 0
    ) {
      return <Text>No Hourly Cost Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>Date</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Cost per Hour
          </Text>
        </View>

        {/* Table rows for each date */}
        {Object.entries(data.sorted_hourly_cost).map(([date, cost]) => (
          <View key={date} style={styles.tableRow}>
            <Text style={styles.tableCell}>{date}</Text>
            <Text style={styles.tableCell}>{cost.toFixed(2)}</Text>
          </View>
        ))}
      </View>
    );
  };
  const renderWeatherTable = () => {
    if (
      !forecastData ||
      !forecastData.forecast ||
      !forecastData.forecast.forecastday
    ) {
      return <Text>No Weather Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>Date</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Max Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Min Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>Condition</Text>
        </View>

        {/* Table rows for each day */}
        {forecastData.forecast.forecastday.map((day, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{day.date}</Text>
            <Text style={styles.tableCell}>{day.day.maxtemp_c}</Text>
            <Text style={styles.tableCell}>{day.day.mintemp_c}</Text>
            <Text style={styles.tableCell}>
              {day.day.condition ? day.day.condition.text : "N/A"}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const renderCostByUserTable = () => {
    if (
      !data.average_cost_per_user ||
      Object.keys(data.average_cost_per_user).length === 0
    ) {
      return <Text>No Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>User</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Average Cost
          </Text>
        </View>

        {/* Table rows for each date */}
        {Object.entries(data.average_cost_per_user).map(([date, cost]) => (
          <View key={date} style={styles.tableRow}>
            <Text style={styles.tableCell}>{date}</Text>
            <Text style={styles.tableCell}>{cost.toFixed(2)}</Text>
          </View>
        ))}
      </View>
    );
  };
  const InfoRow = ({ icon, text }) => (
    <View style={styles1.iconAndText}>
      {icon}
      <Text style={styles1.contentText}>{text}</Text>
    </View>
  );
  const renderPayoutDetails = () => {
    if (!payoutDetails || payoutDetails.length === 0) {
      return <Text>No Payout Details Available</Text>;
    }

    return payoutDetails.map((payout, index) => (
      <View key={index} style={styles.section}>
        <Text
          style={[
            styles.subHeader,
            { textAlign: "center", paddingBottom: "10px" },
          ]}
        >
          Payout Details for {payout.vendor_name}
        </Text>
        <Text style={styles.content}>Approved By: {payout.approved_by}</Text>
        <Text style={styles.content}>
          Total WTT Commission: {payout.total_wtt_commission}
        </Text>
        <Text style={styles.content}>
          Cash Sales: {payout.wtt_cash_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Card Sales: {payout.wtt_card_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Voucher Sales: {payout.wtt_voucher_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total GST in payout: {payout.total_gst.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total Payout: {payout.total_payout.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Payout: {payout.total_wtt_payout.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Card Commission:{" "}
          {payout.total_wtt_card_commission.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Cash Commission:{" "}
          {payout.total_wtt_cash_commission.toFixed(2)}
        </Text>
        <Text style={[styles.content, { paddingBottom: "10px" }]}>
          Total WTT Voucher Commission:{" "}
          {payout.total_wtt_voucher_commission.toFixed(2)}
        </Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Charge Label
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Gross</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>GST</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Net</Text>
          </View>

          {[1, 2, 3, 4, 5].map((num) => {
            const label = payout[`label_misc_charges${num}`];
            const gross = payout[`gross_misc_charges${num}`];
            const gst = payout[`gst_misc_charges${num}`];
            const net = payout[`net_misc_charges${num}`];

            if (label && label.trim() !== "") {
              return (
                <View key={num} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{label}</Text>
                  <Text style={styles.tableCell}>{gross.toFixed(2)}</Text>
                  <Text style={styles.tableCell}>{gst.toFixed(2)}</Text>
                  <Text style={styles.tableCell}>{net.toFixed(2)}</Text>
                </View>
              );
            }
            return null;
          })}
        </View>
      </View>
    ));
  };

  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: "#2459a5" }}>
        <Image src={WTTImage} cache={false} />
      </Page>
      <Page size="A4" style={styles1.page}>
        <View style={styles1.header}>
          <Text style={styles1.salesReport}>EVENT SALES REPORT</Text>
        </View>
        <View style={styles1.section}>
          <Text style={styles1.title}>
            Vendor Name:{" "}
            <Text style={styles.detailValue}>{selectedVendorName}</Text>
          </Text>
          <View style={styles1.detailsSection}>
            <Text style={styles1.detail}>
              Event: <Text style={styles.detailValue}>{selectedEventName}</Text>
            </Text>
            <Text style={styles1.detail}>
              Dates:{" "}
              <Text style={styles.detailValue}>{`${moment(start_date).format(
                "DD-MM-YYYY"
              )} - ${moment(end_date).format("DD-MM-YYYY")}`}</Text>
            </Text>
          </View>
          <View style={styles1.tocSection}>
            <Text style={styles1.tocTitle}>Table of Contents</Text>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Sales Insights</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Labour Cost</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Feedback</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Ratings</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Payout Details</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
            <View style={styles1.tocItem}>
              <Text style={styles1.tocText}>Weather Report</Text>
              <Text style={styles1.tocPage}>...</Text>
            </View>
          </View>
        </View>
        <View style={styles1.footer}></View>
        <Text style={styles1.pageNumber}>2</Text>
      </Page>
      <Page size="A4" style={styles1.page}>
        <View style={styles1.header}>
          <Text style={styles1.salesReport}>EVENT SALES REPORT</Text>
        </View>
        <View style={styles1.contentSection}>
          <InfoRow
            icon={<AccessTimeIcon style={{ color: "#3959a5" }} />}
            text={`Peak Food Time: ${peak_food_time_hour || "N/A"}`}
          />
          <InfoRow
            icon={<AttachMoneyIcon style={{ color: "#3959a5" }} />}
            text={`Total Sales for Peak Hour: $${Number(
              total_sales_for_peak_hour
            ).toFixed(2) || "N/A"}`}
          />
          <InfoRow
            icon={<ShowChartIcon style={{ color: "#3959a5" }} />}
            text={`Total Event Sales: $${Number(total_event_sales).toFixed(2) ||
              "N/A"}`}
          />
          <InfoRow
            icon={<Inventory2Icon style={{ color: "#3959a5" }} />}
            text={`Total Stock Purchased: $${Number(
              total_stock_purchased
            ).toFixed(2) || "N/A"}`}
          />
          <InfoRow
            icon={<AttachMoneyIcon style={{ color: "#3959a5" }} />}
            text={`Average Spent: $${Number(average_spend).toFixed(2) ||
              "N/A"}`}
          />
        </View>

        <View style={styles1.footer}></View>
        <Text style={styles1.pageNumber}>3</Text>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text
            style={[
              styles.subHeader,
              { textAlign: "center", paddingTop: "20px" },
            ]}
          >
            Labour cost per day
          </Text>
          {renderCostByDayTable()}
          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Average Labour cost per Hour
          </Text>
          {renderCostByHourTable()}
          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Avg cost per staff per event
          </Text>
          {renderCostByUserTable()}
          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Feedback
          </Text>
          {renderFeedbackData()}
          <Text style={styles.subHeader}>Ratings</Text>
          {renderRatings()}
          <Text style={styles.subHeader}>Payout Details</Text>
          {renderPayoutDetails()}
          <Text
            style={[
              styles.subHeader,
              { textAlign: "center", paddingBottom: "15px" },
            ]}
          >
            Weather Report
          </Text>
          {renderWeatherTable()}
        </View>
      </Page>
    </Document>
  );
};

const EventReportWithoutCost = ({
  data,
  feedbackData,
  ratings,
  peak_food_time_hour,
  average_spend,
  total_event_sales,
  total_sales_for_peak_hour,
  total_stock_purchased,
  payoutDetails,
  forecastData,
  selectedVendorName,
  selectedEventName,
  start_date,
  end_date,
}) => {
  console.log(start_date, end_date);
  const renderRatings = () => {
    if (!ratings || ratings.length === 0) {
      return <Text>No Ratings Available</Text>;
    }
    return ratings.map((rating, index) => (
      <View key={index} style={styles.section}>
        <Text style={styles.content}>
          {"Rating: " + (Number(rating.ratings_value) / 2).toFixed(1) + "/5"}
        </Text>
        <Text style={styles.content}>{"Comment: " + rating.comments}</Text>
      </View>
    ));
  };
  const renderFeedbackData = () => {
    if (!feedbackData || feedbackData.length === 0) {
      return <Text>No Feedback Data Available</Text>;
    }

    return feedbackData.map((feedback, index) => (
      <View key={index} style={styles.section}>
        <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
          Feedback from {feedback.time_stamp}
        </Text>
        {feedback.feedback_lists.map((item, idx) => (
          <View key={idx} style={styles.table}>
            <Text style={styles.contentQuestion}>{item.feedback_question}</Text>
            <Text style={styles.content}>{item.feedback_answer}</Text>
          </View>
        ))}
      </View>
    ));
  };

  const renderWeatherTable = () => {
    if (
      !forecastData ||
      !forecastData.forecast ||
      !forecastData.forecast.forecastday
    ) {
      return <Text>No Weather Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>Date</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Max Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Min Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>Condition</Text>
        </View>

        {/* Table rows for each day */}
        {forecastData.forecast.forecastday.map((day, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{day.date}</Text>
            <Text style={styles.tableCell}>{day.day.maxtemp_c}</Text>
            <Text style={styles.tableCell}>{day.day.mintemp_c}</Text>
            <Text style={styles.tableCell}>
              {day.day.condition ? day.day.condition.text : "N/A"}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: "#2459a5" }}>
        <Image src={WTTImage} cache={false} />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles1.section}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Text style={{ fontSize: 20, paddingBottom: "20px" }}>
              Event Report
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Vendor Name: {selectedVendorName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Event: {selectedEventName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              {`${moment(start_date).format("DD-MM-YYYY")}`} -{" "}
              {`${moment(end_date).format("DD-MM-YYYY")}`}
            </Text>
          </div>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "20px" },
            ]}
          >
            Peak Food Time: {peak_food_time_hour || "N/A"}
          </Text>
          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Feedback
          </Text>
          {renderFeedbackData()}
          <Text style={styles.subHeader}>Ratings</Text>
          {renderRatings()}
          <Text
            style={[
              styles.subHeader,
              { textAlign: "center", paddingBottom: "15px" },
            ]}
          >
            Weather Report
          </Text>
          {renderWeatherTable()}
        </View>
      </Page>
    </Document>
  );
};

const EventReportSales = ({
  data,
  feedbackData,
  ratings,
  peak_food_time_hour,
  average_spend,
  total_event_sales,
  total_sales_for_peak_hour,
  total_stock_purchased,
  payoutDetails,
  forecastData,
  selectedVendorName,
  selectedEventName,
  start_date,
  end_date,
}) => {
  console.log(start_date, end_date);
  const renderRatings = () => {
    if (!ratings || ratings.length === 0) {
      return <Text>No Ratings Available</Text>;
    }
    return ratings.map((rating, index) => (
      <View key={index} style={styles.section}>
        <Text style={styles.content}>
          {"Rating: " + (Number(rating.ratings_value) / 2).toFixed(1) + "/5"}
        </Text>
        <Text style={styles.content}>{"Comment: " + rating.comments}</Text>
      </View>
    ));
  };
  const renderFeedbackData = () => {
    if (!feedbackData || feedbackData.length === 0) {
      return <Text>No Feedback Data Available</Text>;
    }

    return feedbackData.map((feedback, index) => (
      <View key={index} style={styles.section}>
        <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
          Feedback from {feedback.time_stamp}
        </Text>
        {feedback.feedback_lists.map((item, idx) => (
          <View key={idx} style={styles.table}>
            <Text style={styles.contentQuestion}>{item.feedback_question}</Text>
            <Text style={styles.content}>{item.feedback_answer}</Text>
          </View>
        ))}
      </View>
    ));
  };

  const renderWeatherTable = () => {
    if (
      !forecastData ||
      !forecastData.forecast ||
      !forecastData.forecast.forecastday
    ) {
      return <Text>No Weather Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>Date</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Max Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Min Temp (C)
          </Text>
          <Text style={[styles.tableCell, styles.headerCell]}>Condition</Text>
        </View>

        {/* Table rows for each day */}
        {forecastData.forecast.forecastday.map((day, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{day.date}</Text>
            <Text style={styles.tableCell}>{day.day.maxtemp_c}</Text>
            <Text style={styles.tableCell}>{day.day.mintemp_c}</Text>
            <Text style={styles.tableCell}>
              {day.day.condition ? day.day.condition.text : "N/A"}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const renderCostByUserTable = () => {
    if (
      !data.average_cost_per_user ||
      Object.keys(data.average_cost_per_user).length === 0
    ) {
      return <Text>No Data Available</Text>;
    }

    return (
      <View style={styles.table}>
        {/* Table header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.headerCell]}>User</Text>
          <Text style={[styles.tableCell, styles.headerCell]}>
            Average Cost
          </Text>
        </View>

        {/* Table rows for each date */}
        {Object.entries(data.average_cost_per_user).map(([date, cost]) => (
          <View key={date} style={styles.tableRow}>
            <Text style={styles.tableCell}>{date}</Text>
            <Text style={styles.tableCell}>{cost.toFixed(2)}</Text>
          </View>
        ))}
      </View>
    );
  };
  const renderPayoutDetails = () => {
    if (!payoutDetails || payoutDetails.length === 0) {
      return <Text>No Payout Details Available</Text>;
    }

    return payoutDetails.map((payout, index) => (
      <View key={index} style={styles.section}>
        <Text
          style={[
            styles.subHeader,
            { textAlign: "center", paddingBottom: "10px" },
          ]}
        >
          Payout Details for {payout.vendor_name}
        </Text>
        <Text style={styles.content}>Approved By: {payout.approved_by}</Text>
        <Text style={styles.content}>
          Total WTT Commission: {payout.total_wtt_commission}
        </Text>
        <Text style={styles.content}>
          Cash Sales: {payout.wtt_cash_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Card Sales: {payout.wtt_card_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Voucher Sales: {payout.wtt_voucher_sales.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total GST in payout: {payout.total_gst.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total Payout: {payout.total_payout.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Payout: {payout.total_wtt_payout.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Card Commission:{" "}
          {payout.total_wtt_card_commission.toFixed(2)}
        </Text>
        <Text style={styles.content}>
          Total WTT Cash Commission:{" "}
          {payout.total_wtt_cash_commission.toFixed(2)}
        </Text>
        <Text style={[styles.content, { paddingBottom: "10px" }]}>
          Total WTT Voucher Commission:{" "}
          {payout.total_wtt_voucher_commission.toFixed(2)}
        </Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.headerCell]}>
              Charge Label
            </Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Gross</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>GST</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Net</Text>
          </View>

          {[1, 2, 3, 4, 5].map((num) => {
            const label = payout[`label_misc_charges${num}`];
            const gross = payout[`gross_misc_charges${num}`];
            const gst = payout[`gst_misc_charges${num}`];
            const net = payout[`net_misc_charges${num}`];

            if (label && label.trim() !== "") {
              return (
                <View key={num} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{label}</Text>
                  <Text style={styles.tableCell}>{gross.toFixed(2)}</Text>
                  <Text style={styles.tableCell}>{gst.toFixed(2)}</Text>
                  <Text style={styles.tableCell}>{net.toFixed(2)}</Text>
                </View>
              );
            }
            return null;
          })}
        </View>
      </View>
    ));
  };

  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: "#2459a5" }}>
        <Image src={WTTImage} cache={false} />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Text style={{ fontSize: 20, paddingBottom: "20px" }}>
              Event Report
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Vendor Name: {selectedVendorName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Event: {selectedEventName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              {`${moment(start_date).format("DD-MM-YYYY")}`} -{" "}
              {`${moment(end_date).format("DD-MM-YYYY")}`}
            </Text>
          </div>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Text style={{ fontSize: 20, paddingBottom: "20px" }}>
              Event Report
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Vendor Name: {selectedVendorName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              Event: {selectedEventName}
            </Text>
            <Text style={{ fontSize: 15, paddingBottom: "20px" }}>
              {`${moment(start_date).format("DD-MM-YYYY")}`} -{" "}
              {`${moment(end_date).format("DD-MM-YYYY")}`}
            </Text>
          </div>

          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "20px" },
            ]}
          >
            Peak Food Time: {peak_food_time_hour || "N/A"}
          </Text>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          >
            Total Sales for Peak Hour:{" "}
            {Number(total_sales_for_peak_hour).toFixed(2) || "N/A"}
          </Text>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          >
            Total Event Sales: {Number(total_event_sales).toFixed(2) || "N/A"}
          </Text>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          >
            Total Stock Purchased:{" "}
            {Number(total_stock_purchased).toFixed(2) || "N/A"}
          </Text>
          <Text
            style={[
              styles.content,
              { textAlign: "center", paddingTop: "10px" },
            ]}
          >
            Average Spent: {Number(average_spend).toFixed(2) || "N/A"}
          </Text>

          <Text style={[styles.subHeader, { paddingBottom: "10px" }]}>
            Feedback
          </Text>
          {renderFeedbackData()}
          <Text style={styles.subHeader}>Ratings</Text>
          {renderRatings()}
          <Text
            style={[
              styles.subHeader,
              { textAlign: "center", paddingBottom: "15px" },
            ]}
          >
            Weather Report
          </Text>
          {renderWeatherTable()}
        </View>
      </Page>
    </Document>
  );
};

class AdminPostEventReportScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    event_organiser_email: "",
    event_organiser_phone: "",
    truck_manager_email: "",
    truck_manager_phone: "",
    event_organiser_name: "",
    truck_manager_name: "",
    showEventModal: false,
    showTruckModal: false,
    showFeedbackModal: false,
    showAddStakeholderModal: false,
    showSocialMediaFeedbackModal: false,
    showMarketingConclusionModal: false,
    stakeholder_first_name: "",
    stakeholder_last_name: "",
    stakeholder_email: "",
    stakeholder_phone: "",
    stakeholder_type: "",
    site_area: [],
    events_for_organiser: [],
    site_area_list: [],
    stalls_linked: [],
    events_list: [],
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    phoneNumberValidEventOrganiser: true,
    showWeatherModal: false,
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    salesModal: false,
    billModal: false,
    eventExpensesModal: false,
    eventSummaryModal: false,
    compareModal: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    forecastData: {},
    payoutDetails: {},
    payoutDetailsArray: [],
    shiftData: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    total_event_sales: 0,
    total_stock_purchased: 0,
    average_spend: 0,
    feedback_data: [],
    ratings_from_organiser: [],
    event_expenses: [],
    total_orders: 0,

    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    peak_food_time_hour: "",
    total_sales_for_peak_hour: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    payoutArray: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };
  handleShowSalesModal = () => {
    this.setState({ salesModal: true });
  };
  handleCloseSalesModal = () => {
    this.setState({ salesModal: false });
  };
  handleShowBillModal = () => {
    this.setState({ billModal: true });
  };
  handleCloseBillModal = () => {
    this.setState({ billModal: false });
  };
  handleShowEventExpensesModal = () => {
    this.setState({ eventExpensesModal: true });
  };
  handleCloseEventExpenses = () => {
    this.setState({ eventExpensesModal: false });
  };
  handleShowEventSummaryModal = () => {
    this.setState({ eventSummaryModal: true });
  };
  handleCloseEventSummaryModal = () => {
    this.setState({ eventSummaryModal: false });
  };
  handleShowCompareModal = () => {
    this.setState({ compareModal: true });
  };
  handleCloseCompareModal = () => {
    this.setState({ compareModal: false });
  };
  handleSiteAreaChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ site_area: selectedIds });
  };
  handleEventsChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ events_for_organiser: selectedIds });
  };
  handleVendorsChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ stalls_linked: selectedIds });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  getTooltipContent(tooltipModel) {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.cost_by_day) {
      return;
    }

    const date = tooltipModel.title[0];
    const dailyData = shiftData.cost_by_day[date];
    let tooltipContent = [`Total Cost: ${dailyData.total_cost}`];

    Object.keys(dailyData.user_costs).forEach((userName) => {
      tooltipContent.push(
        `${userName}: $${dailyData.user_costs[userName]}, Hours: ${dailyData.hours_worked[userName]}`
      );
    });

    return tooltipContent;
  }

  renderCostByDayChart() {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.cost_by_day) {
      return null;
    }

    const data = {
      labels: Object.keys(shiftData.cost_by_day),
      datasets: [
        {
          label: "Total Cost by Day",
          data: Object.values(shiftData.cost_by_day).map(
            (day) => day.total_cost
          ),
          backgroundColor: "rgba(0, 123, 255, 0.5)",
          hoverBackgroundColor: "rgba(0, 123, 255, 0.7)",
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: function(context) {
              return `Date: ${context[0].label}`;
            },
            label: function(context) {
              const date = context.label;
              const dayData = shiftData.cost_by_day[date];
              let labels = [`Total Cost: $${dayData.total_cost.toFixed(2)}`];

              Object.entries(dayData.user_costs).forEach(([user, cost]) => {
                labels.push(
                  `${user}: $${cost.toFixed(2)}, Hours: ${dayData.hours_worked[
                    user
                  ].toFixed(2)}, Avg: ${dayData.avg_cost_per_hour[user].toFixed(
                    2
                  )}`
                );
              });

              return labels;
            },
          },
          displayColors: false,
        },
      },
    };

    return <Bar data={data} options={options} />;
  }

  renderCostByTotalHourChart() {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.sorted_hourly_cost) {
      return null; // or some placeholder content
    }

    // Function to calculate the percentage difference
    const calculatePercentageDifference = (current, previous) => {
      if (previous === 0) return "N/A"; // Avoid division by zero
      return (((current - previous) / previous) * 100).toFixed(2);
    };
    const calculatePercentageOfTotal = (cost, total) => {
      return ((cost / total) * 100).toFixed(2);
    };

    // Preparing the data for the chart
    const hours = Object.keys(shiftData.sorted_hourly_cost).sort();
    const costs = hours.map((hour) => shiftData.sorted_hourly_cost[hour]);
    const previousCosts = [0, ...costs.slice(0, -1)]; // Shift the costs by one to align with previous hour
    const percentageDiffs = costs.map((cost, index) =>
      calculatePercentageDifference(cost, previousCosts[index])
    );
    const percentagesOfTotal = costs.map((cost) =>
      calculatePercentageOfTotal(cost, shiftData.total_cost)
    );

    const chartData = {
      labels: hours,
      datasets: [
        {
          label: "Total Labour Cost per Hour",
          data: costs,
          backgroundColor: ["purple", "orange", "pink", "grey"], // Set your desired colors
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            afterLabel: function(context) {
              const index = context.dataIndex;
              const percentageDiff = percentageDiffs[index];
              const percentageOfTotal = percentagesOfTotal[index];
              return [
                `${percentageDiff}% ${
                  percentageDiff >= 0 ? "Increase" : "Decrease"
                } from Last Hour`,
                `${percentageOfTotal}% Percentage of Total Labour Cost `,
              ];
            },
          },
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  }

  renderCostByHourChart() {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.cost_by_hour) {
      return null; // or some placeholder content
    }

    const data = {
      labels: Object.keys(shiftData.cost_by_hour).map((date) =>
        moment(date).format("DD-MM-YYYY")
      ),
      datasets: [
        {
          label: "Average Labour Hourly Cost Per Day Based On Duration",
          data: Object.values(shiftData.cost_by_hour),
          backgroundColor: ["purple", "orange", "pink", "grey"], // Set your desired colors
        },
      ],
    };

    return <Bar data={data} />;
  }

  renderAverageCostPerUserChart() {
    const { shiftData } = this.state;

    if (!shiftData || !shiftData.average_cost_per_user) {
      return null; // or some placeholder content
    }
    const chartColors = [
      "rgba(255, 99, 132, 0.8)", // red
      "rgba(54, 162, 235, 0.8)", // blue
      "rgba(255, 206, 86, 0.8)", // yellow
      "rgba(75, 192, 192, 0.8)", // green
      "rgba(153, 102, 255, 0.8)", // purple
      "rgba(255, 159, 64, 0.8)", // orange
      // ... add more colors if needed
    ];
    const data = {
      labels: Object.keys(shiftData.average_cost_per_user),
      datasets: [
        {
          data: Object.values(shiftData.average_cost_per_user),
          backgroundColor: chartColors.slice(
            0,
            Object.keys(shiftData.average_cost_per_user).length
          ),
        },
      ],
    };

    return <Pie data={data} />;
  }

  componentDidMount() {
    this.getSiteAreas();
    this.getEvents();
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleButtonClick = () => {
    this.setState({ showEventModal: true });
  };
  handleFeedbackButtonClick = () => {
    this.setState({ showFeedbackModal: true });
  };
  handleSocialMediaFeedback = () => {
    this.setState({ showSocialMediaFeedbackModal: true });
  };
  handleMarketingConclusionModal = () => {
    this.setState({ showMarketingConclusionModal: true });
  };
  handleMarketingConclusionModalClose = () => {
    this.setState({ showMarketingConclusionModal: false });
  };
  handleSocialMediaFeedbackModalClose = () => {
    this.setState({ showSocialMediaFeedbackModal: false });
  };
  handleModalClose = () => {
    this.setState({ showEventModal: false });
  };
  handleTruckModalClose = () => {
    this.setState({ showTruckModal: false });
  };
  handleTruckModalShow = () => {
    this.setState({ showTruckModal: true });
  };
  handleFeedbackModalClose = () => {
    this.setState({ showFeedbackModal: false });
  };
  handleFeedbackModalShow = () => {
    this.setState({ showFeedbackModal: true });
  };
  handleAddStakeholderModalClose = () => {
    this.setState({ showAddStakeholderModal: false });
  };
  handleAddStakeholderModalShow = () => {
    this.setState({ showAddStakeholderModal: true });
  };
  handleAddStakeholderButtonClick = () => {
    this.setState({ showAddStakeholderModal: true });
  };
  handleStakeholderTypeChange = (e) => {
    this.setState({ stakeholder_type: e.target.value });
  };

  handleFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    // if (!isPhoneNumberValid) {
    //   this.setState({ phoneNumberValid: false });
    //   return;
    // }

    // If the phone number is valid, you can proceed with other actions
    // console.log("Email:", this.state.email);
    // console.log("Phone:", this.state.phone);

    // API Call

    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_reminder_to_truck_manager",
          vendor_id: selectedVendorId,
          event_id: selectedEventId,
          truck_manager_name: this.state.truck_manager_name,
          email: this.state.truck_manager_email,
          phone: this.state.truck_manager_phone,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState({ showModal: false });
          alert("Reminder Sent Successfully To Truck Manager");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });

    // Close the modal
  };

  handleEventOrganiserReminderFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_reminder_to_event_organisers_for_feedback",
          vendor_id: selectedVendorId,
          event_id: selectedEventId,
          organiser_name: this.state.event_organiser_name,
          email: this.state.event_organiser_email,
          phone: this.state.event_organiser_phone,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState({ showModal: false });
          alert("Reminder Sent Successfully To Truck Manager");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });

    // Close the modal
  };

  handleAddStakeholderFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "create_admin_user",
          stakeholder_first_name: this.state.stakeholder_first_name,
          stakeholder_last_name: this.state.stakeholder_last_name,
          email: this.state.stakeholder_email,
          stakeholder_type: this.state.stakeholder_type,
          events_for_organiser: this.state.events_for_organiser,
          stalls_linked: this.state.stalls_linked,
          site_area: this.state.site_area,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          console.log(dataResponse);
          //this.setState({ showModal: false });
          alert("Reminder Sent Successfully To Stakeholder");
          this.setState({ stalls_linked: [] });
          this.setState({ site_area: [] });
          this.setState({ events_for_organiser: [] });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getSiteAreas = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
      selectedVendorId,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_site_areas",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ site_area_list: dataResponse.vendors }, () => {
            this.setState({ selectedVendorId: selectedVendorId }, () => {
              saveToLocalStorage("selectedVendorId", selectedVendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  getEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events_list: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = (selectedVendorId) => {
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_participated_events",
          vendor_id: selectedVendorId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState(
            {
              events: dataResponse.data.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getOrders();
              }
              this.getOrders();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_post_event_report_for_vendors",
          email: email,
          password: password,
          vendor_id: selectedVendorId,
          event_id: selectedEventId,
          // selected_start_order_id: selectedStartOrderId,
          // selected_end_order_id: selectedEndOrderId,
          // selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          const payoutDetailsArray = dataResponse.payout_details;
          const cumulativeDetails = payoutDetailsArray.reduce(
            (accumulator, current) => {
              accumulator.total_wtt_payout += current.total_wtt_payout || 0;
              accumulator.wtt_card_sales += current.wtt_card_sales || 0;
              accumulator.wtt_cash_sales += current.wtt_cash_sales || 0;
              accumulator.wtt_voucher_sales += current.wtt_voucher_sales || 0;
              accumulator.total_wtt_card_commission +=
                current.total_wtt_card_commission || 0;
              accumulator.total_wtt_cash_commission +=
                current.total_wtt_cash_commission || 0;
              accumulator.total_wtt_voucher_commission +=
                current.total_wtt_voucher_commission || 0;
              accumulator.total_gst += current.total_gst || 0;

              return accumulator;
            },
            {
              total_wtt_payout: 0,
              wtt_card_sales: 0,
              wtt_cash_sales: 0,
              wtt_voucher_sales: 0,
              total_wtt_card_commission: 0,
              total_wtt_cash_commission: 0,
              total_wtt_voucher_commission: 0,
              total_gst: 0,
            }
          );

          const sortedMealFreq = dataResponse.meal_freq.sort(
            (a, b) => b.price - a.price
          );

          this.setState({
            payoutDetailsArray: dataResponse.payout_details,
            peak_food_time_hour: dataResponse.peak_food_time_hour,
            total_sales_for_peak_hour: dataResponse.total_sales_for_peak_hour,
            total_event_sales: dataResponse.total_event_sales,
            total_stock_purchased: dataResponse.total_stock_purchased,
            average_spend: dataResponse.average_spend,
            feedback_data: dataResponse.feedback_data,
            ratings_from_organiser: dataResponse.ratings_from_organiser,
            event_expenses: dataResponse.event_expenses,
            forecastData: dataResponse.weather_info,
            payoutDetails: cumulativeDetails,
            payoutArray: dataResponse.payout_details,
            shiftData: dataResponse.shifts_data,
            meal_freq: sortedMealFreq,
          });
        } else {
          this.setState({
            payoutDetailsArray: [],
            peak_food_time_hour: "",
            total_sales_for_peak_hour: 0,
            total_event_sales: 0,
            total_stock_purchased: 0,
            average_spend: 0,
            feedback_data: [],
            ratings_from_organiser: [],
            event_expenses: [],
            forecastData: {},
            payoutDetails: {},
            payoutArray: [],
            shiftData: {},
          });
        }

        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];

        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  //   Get Hourly

  getHourlySales = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isHourlyGraphLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_hourly_sales",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            salesByHours: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isHourlyGraphLoading: false });
        this.getMealFrequency();
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });

        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  handleShow = () => {
    this.setState({ showWeatherModal: true });
  };

  handleClose = () => {
    this.setState({ showWeatherModal: false });
  };

  getMealFrequency = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }

    this.setState({ isMealFreqLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_by_vendor_graphs",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      timeout: 120000,
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            meal_freq: dataResponse.meal_freq,
          });
          // var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        // for (let [key, value] of Object.entries(salesByHoursObj)) {
        //   salesByHoursLabelsTemp.push(key);
        // }

        // salesByHoursLabelsTemp.sort();
        // var len = salesByHoursLabelsTemp.length;
        // for (var i = 0; i < len; i++) {
        //   var k = salesByHoursLabelsTemp[i];
        //   salesByHoursDataTemp.push(salesByHoursObj[k]);
        // }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isMealFreqLoading: false });
        // this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        // this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isSuperAdminLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_vendors",
            // operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.vendors }, () => {
            console.log(dataResponse.vendors);
            let vendorId = "0";
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      isLoading,
      events,
      peak_food_time_hour,
      total_sales_for_peak_hour,
      total_event_sales,
      total_stock_purchased,
      average_spend,
      feedback_data,
      ratings_from_organiser,
      event_expenses,
      selectedEvent,
      vendors,
      selectedVendorId,
      total_sales,
      payoutArray,
      totalCashOrders,
      totalCardOrders,
      forecastData,
      payoutDetails,
      shiftData,
      payoutDetailsArray,
      selectedEventId,
    } = this.state;
    const siteAreaOptions = this.state.site_area_list.map((site) => ({
      value: site.id,
      label: site.site_name,
    }));
    const eventsOptions = this.state.events_list.map((site) => ({
      value: site.id,
      label: site.name,
    }));
    const vendorsOptions = this.state.vendors.map((site) => ({
      value: site.user_ptr_id,
      label: site.title,
    }));

    const { showWeatherModal } = this.state;
    const Item = styled(Paper)(({ theme }) => ({
      // backgroundColor: "rgba(41, 89, 165, 0.3)",
      backgroundColor: "#2459a5",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      display: "flex", // Align items horizontally
      justifyContent: "center", // Center items horizontally
    }));
    let new_total_orders = totalCashOrders + totalCardOrders;
    const averageOrder =
      Number(total_sales).toFixed(2) / Number(new_total_orders).toFixed(2);
    const setNewRating = (data) => {
      this.setState({ selectedRating: data * 2 });
    };
    return (
      <div className="expenses-container">
        {!isLoading ? (
          <>
            <React.Fragment>
              <CssBaseline />
              <Container>
                <div
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    backgroundColor: "#113a7a",
                    color: "#113a7a",
                  }}
                ></div>
                <h2
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#f1f1f1",
                  }}
                >
                  Event Report
                </h2>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={8}>
                    <Item
                      style={{ backgroundColor: "#fff", borderRadius: "20px" }}
                    >
                      <div className="icon-container">
                        <LocalShippingIcon
                          sx={{ fontSize: 40, color: "#ef3e6d" }}
                        />
                      </div>
                      <div className="autocomplete-container">
                        <Autocomplete
                          options={vendors}
                          getOptionLabel={(option) => `${option.title}`}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Vendor"
                              variant="standard"
                            />
                          )}
                          onChange={(e, newValue) => {
                            const selectedVendorId = newValue
                              ? newValue.user_ptr_id
                              : "";
                            const selectedVendorName = newValue
                              ? newValue.title
                              : "";

                            // Save the selectedVendorId to localStorage
                            localStorage.setItem(
                              "selectedVendorId",
                              selectedVendorId
                            );

                            // Update the state
                            this.setState(
                              { selectedVendorId, selectedVendorName },
                              () => {
                                this.getAllEvents(selectedVendorId);
                              }
                            );
                          }}
                          value={
                            vendors.find(
                              (vendor) =>
                                vendor.user_ptr_id ===
                                this.state.selectedVendorId
                            ) || null
                          }
                        />
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item
                      style={{ backgroundColor: "#fff", borderRadius: "20px" }}
                    >
                      <div className="icon-container">
                        <CalendarMonthIcon
                          sx={{ fontSize: 40, color: "#ef3e6d" }}
                        />
                      </div>
                      <div className="autocomplete-container">
                        <Autocomplete
                          options={events}
                          getOptionLabel={(option) => `${option.name}`}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Event"
                              variant="standard"
                            />
                          )}
                          onChange={(e, newValue) => {
                            const selectedEventId = newValue ? newValue.id : "";
                            const selectedEventName = newValue
                              ? newValue.name
                              : "";
                            this.setState(
                              { selectedEventId, selectedEventName },
                              () => {
                                this.getOrders(
                                  localStorage.getItem("selectedVendorId"),
                                  selectedEventId
                                );
                              }
                            );
                            saveToLocalStorage(
                              "selectedEventId",
                              selectedEventId
                            );
                          }}
                          value={
                            events.find(
                              (event) => event.id === this.state.selectedEventId
                            ) || null
                          }
                        />
                      </div>
                    </Item>
                  </Grid>
                </Grid>
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Total Event Sales
                          <AttachMoneyIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>

                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${total_event_sales}
                          </span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Peak Sales Hour{" "}
                          <TrendingUpIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          The highest sales occur at{" "}
                          <span
                            style={{
                              color: green[500],
                              fontSize: "1.1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {peak_food_time_hour}
                          </span>
                          , generating a total of{" "}
                          <span
                            style={{
                              color: green[500],
                              fontSize: "1.1rem",
                              fontWeight: "bold",
                            }}
                          >
                            ${total_sales_for_peak_hour}
                          </span>
                          .
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      onClick={this.handleShow}
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Weather Details{" "}
                          <WbSunnyIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <WeatherForecastModal
                          forecastData={forecastData}
                          show={showWeatherModal}
                          handleClose={this.handleClose}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Average Spend{" "}
                          <FunctionsIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${average_spend.toFixed(2)}
                          </span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Total Payout{" "}
                          <ContactlessIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            {payoutDetails &&
                              payoutDetails.total_wtt_payout !== undefined && (
                                <div>{payoutDetails.total_wtt_payout}</div>
                              )}
                          </span>
                          <p
                            className="see-details-btn"
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowSalesModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Modal
                    show={this.state.salesModal}
                    onHide={this.handleCloseSalesModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        Payout Details
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {payoutDetails &&
                        payoutDetails.wtt_card_sales !== undefined && (
                          <p>
                            Cash Sales: $
                            {Number(payoutDetails.wtt_card_sales).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.wtt_cash_sales !== undefined && (
                          <p>
                            Cash Sales: $
                            {Number(payoutDetails.wtt_cash_sales).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.wtt_voucher_sales !== undefined && (
                          <p>
                            Voucher Sales: $
                            {Number(payoutDetails.wtt_voucher_sales).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_wtt_card_commission !==
                          undefined && (
                          <p>
                            Card Commission: $
                            {Number(
                              payoutDetails.total_wtt_card_commission
                            ).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_wtt_cash_commission !==
                          undefined && (
                          <p>
                            Cash Commission: $
                            {Number(
                              payoutDetails.total_wtt_cash_commission
                            ).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_wtt_voucher_commission !==
                          undefined && (
                          <p>
                            Voucher Commission: $
                            {Number(
                              payoutDetails.total_wtt_voucher_commission
                            ).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.wtt_card_sales !== undefined &&
                        payoutDetails.wtt_cash_sales !== undefined &&
                        payoutDetails.wtt_voucher_sales !== undefined && (
                          <p>
                            Total Revenue: $
                            {Number(
                              payoutDetails.wtt_card_sales +
                                payoutDetails.wtt_cash_sales +
                                payoutDetails.wtt_voucher_sales
                            ).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_gst !== undefined && (
                          <p>
                            Total GST: $
                            {Number(payoutDetails.total_gst).toFixed(2)}
                          </p>
                        )}

                      {payoutDetails &&
                        payoutDetails.total_wtt_payout !== undefined && (
                          <p>
                            Payout: $
                            {Number(payoutDetails.total_wtt_payout).toFixed(2)}
                          </p>
                        )}
                      {payoutArray.map((payout, index) => (
                        <Accordion
                          key={payout.id}
                          style={{ marginBottom: "10px" }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{
                              backgroundColor: "#2959a5",
                              color: "white",
                            }}
                            aria-controls={`panel${index}a-content`}
                            id={`panel${index}a-header`}
                          >
                            <Typography>
                              Payout {index + 1} - {payout.vendor__title} $
                              {Number(payout.total_wtt_payout).toFixed(2)}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography component={"span"}>
                              <p>
                                Cash Sales: $
                                {Number(payout.wtt_cash_sales).toFixed(2)}
                              </p>
                              <p>
                                Card Sales: $
                                {Number(payout.wtt_card_sales).toFixed(2)}
                              </p>
                              <p>
                                Voucher Sales: $
                                {Number(payout.wtt_voucher_sales).toFixed(2)}
                              </p>
                              <p>
                                Card Commission: $
                                {Number(
                                  payout.total_wtt_card_commission
                                ).toFixed(2)}
                              </p>
                              <p>
                                Cash Commission: $
                                {Number(
                                  payout.total_wtt_cash_commission
                                ).toFixed(2)}
                              </p>
                              <p>
                                Voucher Commission: $
                                {Number(
                                  payout.total_wtt_voucher_commission
                                ).toFixed(2)}
                              </p>
                              <p>
                                Total GST: $
                                {Number(payout.total_gst).toFixed(2)}
                              </p>
                              <p>
                                Total Payout: $
                                {Number(payout.total_wtt_payout).toFixed(2)}
                              </p>
                              {["1", "2", "3", "4", "5"].map((num) => {
                                if (
                                  payout[`label_misc_charges${num}`] &&
                                  payout[`gross_misc_charges${num}`]
                                ) {
                                  return (
                                    <div key={num}>
                                      <h6>
                                        {payout[`label_misc_charges${num}`]}
                                      </h6>
                                      <p>
                                        Gross: $
                                        {Number(
                                          payout[`gross_misc_charges${num}`]
                                        ).toFixed(2)}
                                      </p>
                                      <p>
                                        GST: $
                                        {Number(
                                          payout[`gst_misc_charges${num}`]
                                        ).toFixed(2)}
                                      </p>
                                      <p>
                                        Net: $
                                        {Number(
                                          payout[`net_misc_charges${num}`]
                                        ).toFixed(2)}
                                      </p>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Modal.Body>
                  </Modal>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Stock Purchase{" "}
                          <InventoryIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${total_stock_purchased}
                          </span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "200px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Wage Bill{" "}
                          <PaymentsIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            ${Number(shiftData.total_cost).toFixed(2)}{" "}
                          </span>
                          <p
                            className="see-details-btn"
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowBillModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.billModal}
                      onHide={this.handleCloseBillModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Bill Details
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h5>
                          Total Cost: {Number(shiftData.total_cost).toFixed(2)}
                        </h5>
                        {this.renderCostByDayChart()}
                        {this.renderCostByTotalHourChart()}
                        {this.renderCostByHourChart()}
                        {this.renderAverageCostPerUserChart()}
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Gross Earnings{" "}
                          <AccountBalanceIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="see-details-btn"
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowCompareModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.compareModal}
                      onHide={this.handleCloseCompareModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Sales Compared to Expenditure
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>Total Sales: ${total_event_sales}</p>

                        <p>
                          Total Wages: $
                          {Number(shiftData.total_cost).toFixed(2)} (
                          {(
                            (Number(shiftData.total_cost) /
                              Number(total_event_sales)) *
                            100
                          ).toFixed(2)}
                          % of Sales)
                        </p>

                        <p>
                          Total Stock Purchased: $
                          {Number(total_stock_purchased).toFixed(2)} (
                          {(
                            (Number(total_stock_purchased) /
                              Number(total_event_sales)) *
                            100
                          ).toFixed(2)}
                          % of Sales)
                        </p>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Event Expenses{" "}
                          <AttachMoneyIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="see-details-btn"
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowEventExpensesModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.eventExpensesModal}
                      onHide={this.handleCloseEventExpenses}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Event Expenses
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Sub Total</th>
                              <th>Is GST Applied</th>
                              <th>GST</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {event_expenses.length != 0 &&
                              event_expenses.expenses_list.map(
                                (expense, index) => (
                                  <tr key={index}>
                                    <td>{expense.title}</td>
                                    <td>{expense.sub_total}</td>
                                    <td>
                                      {expense.is_gst_applied ? "Yes" : "No"}
                                    </td>
                                    <td>{expense.gst}</td>
                                    <td>{expense.total}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Event Summary{" "}
                          <SummarizeIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="see-details-btn"
                            style={{ color: "#2959a5", cursor: "pointer" }}
                            onClick={this.handleShowEventSummaryModal}
                          >
                            See Details
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Modal
                      show={this.state.eventSummaryModal}
                      onHide={this.handleCloseEventSummaryModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Event Summary
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          Total Expenses for the Event:{" "}
                          {event_expenses.total_expenses_for_the_event}
                        </p>
                        <p>Total Site Fees: {event_expenses.total_site_fees}</p>
                        <p>
                          Operator: {event_expenses.operator__first_name}{" "}
                          {event_expenses.operator__last_name}
                        </p>
                        <p>Timestamp: {event_expenses.timestamp}</p>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>

                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Feedback From Vendor{" "}
                          <FeedbackIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="see-details-btn"
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleTruckModalShow}
                          >
                            Ask Truck Manager for feedback
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Ratings from Organiser{" "}
                          <FeedbackIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <ListGroup>
                          {ratings_from_organiser.map((rating, index) => (
                            <ListGroup.Item key={index}>
                              <strong>Ratings :</strong>
                              <StarRating
                                ratingNumber={
                                  rating.ratings_value > 0
                                    ? rating.ratings_value
                                    : 0
                                }
                                setNewRating={setNewRating}
                              />
                              <br />
                              <strong>Comments:</strong> {rating.comments}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                        <p
                          className="see-details-btn"
                          style={{
                            color: "#2959a5",
                            cursor: "pointer",
                          }}
                          onClick={this.handleButtonClick}
                        >
                          Ask Event Organisers for ratings
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          User Feedback
                        </Card.Title>
                        <ListGroup>
                          {ratings_from_organiser.map((rating, index) => (
                            <ListGroup.Item key={index}>
                              <strong>Ratings :</strong>
                              <StarRating
                                ratingNumber={
                                  rating.ratings_value > 0
                                    ? rating.ratings_value
                                    : 0
                                }
                                setNewRating={setNewRating}
                              />
                              <br />
                              <strong>Comments:</strong> {rating.comments}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="see-details-btn"
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleFeedbackButtonClick}
                          >
                            Ask User Feedback
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Social Media Promotion{" "}
                          <InstagramIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="see-details-btn"
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleSocialMediaFeedback}
                          >
                            Post Social Media Promotion
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: "150px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            color: "#2959a5",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Marketing Report{" "}
                          <AssessmentIcon
                            style={{ marginLeft: "0.5rem", color: green[500] }}
                          />
                        </Card.Title>
                        <Card.Text
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="see-details-btn"
                            style={{
                              color: "#2959a5",
                              cursor: "pointer",
                            }}
                            onClick={this.handleMarketingConclusionModal}
                          >
                            Write Marketing Conclusion
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Col>
                    <div
                      style={{
                        borderRadius: "20px",
                        height: "150px",
                      }}
                    >
                      <PDFDownloadLink
                        document={
                          <EventReport
                            data={shiftData}
                            feedbackData={feedback_data}
                            ratings={ratings_from_organiser}
                            peak_food_time_hour={peak_food_time_hour}
                            total_sales_for_peak_hour={
                              total_sales_for_peak_hour
                            }
                            total_event_sales={total_event_sales}
                            total_stock_purchased={total_stock_purchased}
                            average_spend={average_spend}
                            payoutDetails={payoutDetailsArray}
                            forecastData={forecastData}
                            selectedVendorName={this.state.selectedVendorName}
                            selectedEventName={this.state.selectedEventName}
                            start_date={this.state.startDate}
                            end_date={this.state.endDate}
                          />
                        }
                        fileName="event-report.pdf"
                      >
                        {({ loading }) => (
                          <span
                            className="download-rpt-btn"
                            style={{
                              color: "white",
                              textDecoration: "underline",
                            }}
                          >
                            {loading
                              ? "Loading document..."
                              : "Download Report"}
                          </span>
                        )}
                      </PDFDownloadLink>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        borderRadius: "20px",
                        height: "150px",
                      }}
                    >
                      <PDFDownloadLink
                        document={
                          <EventReportWithoutCost
                            feedbackData={feedback_data}
                            ratings={ratings_from_organiser}
                            peak_food_time_hour={peak_food_time_hour}
                            forecastData={forecastData}
                            selectedVendorName={this.state.selectedVendorName}
                            selectedEventName={this.state.selectedEventName}
                            start_date={this.state.startDate}
                            end_date={this.state.endDate}
                          />
                        }
                        fileName="event-report-without-cost.pdf"
                      >
                        {({ loading }) => (
                          <span
                            className="download-rpt-btn"
                            style={{
                              color: "white",
                              textDecoration: "underline",
                            }}
                          >
                            {loading
                              ? "Loading document..."
                              : "Download Report without Cost"}
                          </span>
                        )}
                      </PDFDownloadLink>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        borderRadius: "20px",
                        height: "150px",
                      }}
                    >
                      <PDFDownloadLink
                        document={
                          <EventReportSales
                            data={shiftData}
                            feedbackData={feedback_data}
                            ratings={ratings_from_organiser}
                            peak_food_time_hour={peak_food_time_hour}
                            total_sales_for_peak_hour={
                              total_sales_for_peak_hour
                            }
                            total_event_sales={total_event_sales}
                            total_stock_purchased={total_stock_purchased}
                            average_spend={average_spend}
                            payoutDetails={payoutDetailsArray}
                            forecastData={forecastData}
                            selectedVendorName={this.state.selectedVendorName}
                            selectedEventName={this.state.selectedEventName}
                            start_date={this.state.startDate}
                            end_date={this.state.endDate}
                          />
                        }
                        fileName="event-report-sales.pdf"
                      >
                        {({ loading }) => (
                          <span
                            className="download-rpt-btn"
                            style={{
                              color: "white",
                              textDecoration: "underline",
                            }}
                          >
                            {loading
                              ? "Loading document..."
                              : "Download Report with Sales"}
                          </span>
                        )}
                      </PDFDownloadLink>
                    </div>
                  </Col>
                </Row>
              </Container>
            </React.Fragment>

            <Footer />
          </>
        ) : (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Add a semi-transparent background
              zIndex: 9999, // Ensure it covers everything
            }}
          >
            <img src={require("../../assets/img/loading.gif")} alt="Loading" />
          </div>
        )}
        <Modal show={this.state.showEventModal} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ask Event Organisers for Ratings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleEventOrganiserReminderFormSubmit}>
              <Form.Group controlId="formEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Event Organiser Name"
                  value={this.state.event_organiser_name}
                  onChange={(e) =>
                    this.setState({ event_organiser_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Event Organiser email"
                  value={this.state.event_organiser_email}
                  onChange={(e) =>
                    this.setState({ event_organiser_email: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Event Organiser phone number"
                  value={this.state.event_organiser_phone}
                  onChange={(e) =>
                    this.setState({ event_organiser_phone: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showTruckModal}
          onHide={this.handleTruckModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ask Truck Manager For Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group controlId="formEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Truck Manager Name"
                  value={this.state.truck_manager_name}
                  onChange={(e) =>
                    this.setState({ truck_manager_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Truck Manager Email"
                  value={this.state.truck_manager_email}
                  onChange={(e) =>
                    this.setState({ truck_manager_email: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Truck Manager Phone Number"
                  value={this.state.truck_manager_phone}
                  onChange={(e) =>
                    this.setState({ truck_manager_phone: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showFeedbackModal}
          onHide={this.handleFeedbackModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ask Feedback from Users</Modal.Title>
          </Modal.Header>
          <Container style={{ padding: "20px", maxWidth: "600px" }}>
            <Button
              variant="primary"
              style={{
                backgroundColor: "#2959a5",
                borderColor: "#2959a5",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                width: "100%",
                marginTop: "20px",
                color: "#fff",
              }}
              onClick={this.handleAddStakeholderButtonClick}
            >
              Add User
            </Button>
          </Container>
          <Modal.Body>
            <Form onSubmit={this.handleFeedbackReminderFormSubmit}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showSocialMediaFeedbackModal}
          onHide={this.handleSocialMediaFeedbackModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Promote your event on social media</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocialMediaModal
              selectedEventId={this.state.selectedEventId}
              onClose={this.handleSocialMediaFeedbackModalClose}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showMarketingConclusionModal}
          onHide={this.handleMarketingConclusionModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Marketing Conclusion Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MarketingConclusionModal
              selectedEventId={this.state.selectedEventId}
              onClose={this.handleMarketingConclusionModalClose}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showAddStakeholderModal}
          onHide={this.handleAddStakeholderModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleAddStakeholderFormSubmit}>
              <Form.Group controlId="formAddStakeholderName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  value={this.state.stakeholder_first_name}
                  onChange={(e) =>
                    this.setState({ stakeholder_first_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formAddStakeholderName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  value={this.state.stakeholder_last_name}
                  onChange={(e) =>
                    this.setState({ stakeholder_last_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formAddStakeholderEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  value={this.state.stakeholder_email}
                  onChange={(e) =>
                    this.setState({ stakeholder_email: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formStakeholderType">
                <Form.Label>User Type</Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.stakeholder_type}
                  onChange={this.handleStakeholderTypeChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="organiser">Organiser</option>
                  <option value="operator">Operator</option>
                  <option value="accountant">Accountant</option>
                  <option value="stock_admin">Stock Admin</option>
                  <option value="marketing">Marketing</option>
                  <option value="bdm">BDM</option>
                </Form.Control>
              </Form.Group>
              {this.state.stakeholder_type === "organiser" && (
                <>
                  <Form.Group controlId="formSiteArea">
                    <Form.Label>Site Area</Form.Label>
                    <Select
                      isMulti
                      value={siteAreaOptions.filter((option) =>
                        this.state.site_area.includes(option.value)
                      )}
                      onChange={this.handleSiteAreaChange}
                      options={siteAreaOptions}
                      placeholder="Select Site Area"
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEvents">
                    <Form.Label>Events</Form.Label>
                    <Select
                      isMulti
                      value={eventsOptions.filter((option) =>
                        this.state.events_for_organiser.includes(option.value)
                      )}
                      onChange={this.handleEventsChange}
                      options={eventsOptions}
                      placeholder="Select Events"
                      required
                    />
                  </Form.Group>
                </>
              )}
              {this.state.stakeholder_type === "operator" && (
                <>
                  <Form.Group controlId="formStallsLinked">
                    <Form.Label>Linked Stalls</Form.Label>
                    <Select
                      isMulti
                      value={vendorsOptions.filter((option) =>
                        this.state.stalls_linked.includes(option.value)
                      )}
                      onChange={this.handleVendorsChange}
                      options={vendorsOptions}
                      placeholder="Select Vendors"
                      required
                    />
                  </Form.Group>
                </>
              )}
              <Button
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: "#2959a5",
                  borderColor: "#2959a5",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  width: "100%",
                  marginTop: "20px",
                  color: "#fff",
                }}
              >
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminPostEventReportScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
