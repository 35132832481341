import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Button,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Grid,
  Typography,
  List,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import { styled } from "@mui/material/styles";
import ReactStars from "react-rating-stars-component";
import * as Sentry from "@sentry/react";
import { AWS_URL } from "../../assets/Constants";
import MenuModal from "./MenuModal";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";

import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store"; // Import utility functions

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: '80%',  
  borderRadius: 12,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
  backgroundColor: "#f8f9fa",
  maxHeight: "80vh",
  overflowY: "auto"
}));



const EventModal = ({
  open,
  handleClose,
  vendorDetail,
  eoiID,
  confirmVendor,
  rejectVendor,
}) => {

  const [menuModalOpen, setMenuModalOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [canAcceptReject, setCanAcceptReject] = useState(false);

  const handleCheckboxChange = (event, documentName) => {
    setSelectedDocuments((prev) =>
      event.target.checked
        ? [...prev, documentName]
        : prev.filter((doc) => doc !== documentName)
    );
  };


  useEffect(() => {
    const storedPermission = getFromLocalStorage("canSeepayouts");
    if (storedPermission === 'true') {
      setCanAcceptReject(storedPermission); // It’s already parsed in the utility function
    }
  }, []);

  const alertVendorDocuments = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "upload_alert_for_vendors",
          vendor_id: vendorDetail.id,
          eoi_id: eoiID,
          documents: selectedDocuments,
        },
      },
    });

    fetch(url, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          alert("Alerted Successfully!");
        } else {
          alert("Please try again!");
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        alert("Please try again!");
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      {!menuModalOpen ? (
        <StyledCard>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <img src={vendorDetail.logo} alt="Vendor Logo" style={{ width: "100%", borderRadius: 8 }} />
                <img src={vendorDetail.image} alt="Truck Image" style={{ width: "100%", borderRadius: 8 }} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h5" fontWeight={600} gutterBottom>
                  {vendorDetail.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Company Name: {vendorDetail.comapny_name}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Trading Name: {vendorDetail.trading_name}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Abn Number: {vendorDetail.abn_number} 
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Rego Number: {vendorDetail.rego_number} 
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Outlet Dimensions: L  {vendorDetail.truck_width}m X H  {vendorDetail.truck_height}m X W {vendorDetail.truck_depth}m
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Location: {vendorDetail.address}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Phone: {vendorDetail.phone}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Email: {vendorDetail.email}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Instagram: {vendorDetail.instagram}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Truck's Maximum Serves Per Hour: {vendorDetail.serves_per_hour}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Bio: {vendorDetail.bio}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Sustanablity Practices: {vendorDetail.sustainablity_practices}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Service Side: {vendorDetail.service_side}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Power Requirements: No 10 amp: {vendorDetail.number_of_10_amp} No 15 amp: {vendorDetail.number_of_15_amp} No 20 amp: {vendorDetail.number_of_20_amp} No 32 amp: {vendorDetail.number_of_32_amp} 
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {vendorDetail.liablity_certificate && (
                                    <p>
                                      <strong>Public Liability Certificate:</strong>{" "}
                                      <a
                                        href={vendorDetail.liablity_certificate}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                       
                                      >
                                        Public Liability Certificate
                                      </a>
                                    </p>
                                  )}
                </Typography>

                <Typography variant="body1" color="text.secondary">
                  {vendorDetail.gas_certificate && (
                                    <p>
                                      <strong>Gas Plate::</strong>{" "}
                                      <a
                                        href={vendorDetail.gas_certificate}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                       
                                      >
                                        Gas Plate:
                                      </a>
                                    </p>
                                  )}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {vendorDetail.electricial_certificate && (
                                    <p>
                                      <strong>Electrical Certificate:</strong>{" "}
                                      <a
                                        href={vendorDetail.electricial_certificate}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                       
                                      >
                                        Electrical Certificate:
                                      </a>
                                    </p>
                                  )}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {vendorDetail.food_refistration_docs && (
                                    <p>
                                      <strong>Council Registration Certificate:</strong>{" "}
                                      <a
                                        href={vendorDetail.food_refistration_docs}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                       
                                      >
                                        Council Registration Certificate
                                      </a>
                                    </p>
                                  )}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {vendorDetail.food_safety_certificate && (
                                    <p>
                                      <strong>Food Safety Certificate:</strong>{" "}
                                      <a
                                        href={vendorDetail.food_safety_certificate}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                       
                                      >
                                        Food Safety Certificate
                                      </a>
                                    </p>
                                  )}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {vendorDetail.work_cover_insurance && (
                                    <p>
                                      <strong>Work Cover:</strong>{" "}
                                      <a
                                        href={vendorDetail.work_cover_insurance}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                       
                                      >
                                        Work Cover:
                                      </a>
                                    </p>
                                  )}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {vendorDetail.site_layout && (
                                    <p>
                                      <strong>Site Layout:</strong>{" "}
                                      <a
                                        href={vendorDetail.site_layout}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                       
                                      >
                                        Site Layout:
                                      </a>
                                    </p>
                                  )}
                </Typography>



                
              </Grid>
            </Grid>
            {vendorDetail.meals?.some((meal) => !meal.image || !meal.description) && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                Some menu items are missing images or descriptions!
              </Alert>
            )}
            <Button onClick={() => setMenuModalOpen(true)} color="primary" variant="contained" sx={{ mt: 2 }}>
              Open Full Menu
            </Button>
          </CardContent>
          <DialogActions>

          {canAcceptReject && (
    <>
      <Button onClick={() => confirmVendor(vendorDetail)} color="primary" variant="contained">
        Confirm Vendor
      </Button>
      <Button onClick={() => rejectVendor(vendorDetail)} color="error" variant="contained">
        Reject Vendor
      </Button>
    </>
  )}
           
            <Button onClick={handleClose} color="secondary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </StyledCard>
      ) : (
        <Dialog open={menuModalOpen} onClose={() => setMenuModalOpen(false)} fullScreen>
          <DialogActions>
            <Button onClick={() => setMenuModalOpen(false)} color="secondary" variant="contained">
              Close Menu
            </Button>
          </DialogActions>
          <MenuModal menu={vendorDetail.meals} vendorId={vendorDetail.id} />
        </Dialog>
      )}

      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
          <DialogTitle>
            {"Please select the items you want to notify?"}
          </DialogTitle>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("food_refistration_docs")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "food_refistration_docs")
                  }
                />
              }
              label="Food Registration Certificate"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("liability_certificate")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "liability_certificate")
                  }
                />
              }
              label="Liability Certificate"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("gas_certificate")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "gas_certificate")
                  }
                />
              }
              label="Gas Certificate"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("measurements")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "measurements")
                  }
                />
              }
              label="Truck Measurements"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("food_safety")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "food_safety")
                  }
                />
              }
              label="Food Safety"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes(
                    "fire_safety_certificate"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, "fire_safety_certificate")
                  }
                />
              }
              label="Fire Permit"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes(
                    "working_with_childrens_check"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, "working_with_childrens_check")
                  }
                />
              }
              label="Working With Children Check"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes(
                    "meal_image_and_description"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, "meal_image_and_description")
                  }
                />
              }
              label="Meal Images & Descriptions"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes(
                    "test_tag_fire_equipments"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, "test_tag_fire_equipments")
                  }
                />
              }
              label="Test and Tag Fire Equipments"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("rego_number")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "rego_number")
                  }
                />
              }
              label="Registration Number"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("truck_images")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "truck_images")
                  }
                />
              }
              label="Truck Images"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("staff_uniform_images")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "staff_uniform_images")
                  }
                />
              }
              label="Staff Uniform Pictures"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("confirm_pricing")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "confirm_pricing")
                  }
                />
              }
              label="Confirm Pricing"
            />
          </div>
          <DialogActions>
          <Button onClick={""  }>Cancel</Button>
            <Button
              onClick={() => {
                alertVendorDocuments();
                
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
    </Dialog>
    

  );
};

export default EventModal;
