import React, { Component } from "react";
import { connect } from "react-redux";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import moment from "moment";
import "./style.css";
import {
  Modal,
  Button,
  Table,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Pie, Line } from "react-chartjs-2";
import { store, getFromLocalStorage } from "../../store";
import { Chart, registerables } from "chart.js";

class OperatorDashboard extends Component {
  state = {
    dashboardData: null,
    isLoading: false,
    error: null,
    showModal: false,
    modalContent: null,
    sortConfig: { key: null, direction: "ascending" },
    mergedEOIS: 0,
    selectedFilter: "Yearly", // Default filter
  };

  componentDidMount() {
    this.fetchDashboardData();
  }

  fetchDashboardData = () => {
    this.setState({ isLoading: true, error: null });

    const url = `${config.BASE_URI}${apiPaths.operatorData}`;
    const headers = new Headers({ "Content-Type": "application/json" });

    const body = JSON.stringify({
      payload: {
        body: {
          query_type: "get_dashboard_data_vendor",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          filter_type: this.state.selectedFilter.toLowerCase(), // Pass the selected filter
        },
      },
    });

    fetch(url, { method: "POST", headers, body })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({ dashboardData: data, isLoading: false });
        } else {
          throw new Error("Failed to fetch dashboard data");
        }
      })
      .catch((error) => this.setState({ error, isLoading: false }));
  };

  calculateBuildCost(meal) {
    if (!meal.build || !Array.isArray(meal.build) || meal.build.length === 0) {
      return 0; // Return 0 if build is not defined, not an array, or empty
    }

    let totalBuildCost = 0;

    meal.build.forEach((buildGroup) => {
      if (!Array.isArray(buildGroup)) return; // Skip invalid build groups
      buildGroup.forEach((item) => {
        let cost = 0;

        if (
          item.stocklist__weight_value > 1 &&
          item.unit === "Grams" &&
          item.stocklist__price > 0
        ) {
          const costPerGram = item.stocklist__price / 1000; // Assume price per kg if not specified
          cost = costPerGram * item.quantity_per_meal;
        } else if (item.stocklist__price > 0) {
          cost = item.stocklist__price * item.quantity_per_meal; // Use price per unit
        }

        totalBuildCost += cost;
      });
    });

    return totalBuildCost;
  }

  // Render the sales comparison graph for 2024 vs 2025
  renderComparisonGraph = () => {
    const { dashboardData } = this.state;
    if (
      !dashboardData ||
      !dashboardData.orders_in_2025 ||
      !dashboardData.orders_in_2024
    )
      return null;

    // Extract monthly sales data for 2024 & 2025
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const sales2025 = months.map(
      (month) => dashboardData.orders_in_2025[`${month} 2025`] || 0
    );
    const sales2024 = months.map(
      (month) => dashboardData.orders_in_2024[`${month} 2024`] || 0
    );

    // Calculate percentage change compared to previous year
    const percentageChange = sales2025.map((sales, index) => {
      const lastYearSales = sales2024[index];
      if (lastYearSales === 0) return sales > 0 ? 100 : 0; // Avoid division by zero
      return (((sales - lastYearSales) / lastYearSales) * 100).toFixed(2);
    });

    // Chart.js data
    const chartData = {
      labels: months,
      datasets: [
        {
          label: "Sales in 2025",
          data: sales2025,
          borderColor: "blue",
          backgroundColor: "rgba(0, 0, 255, 0.2)",
          fill: true,
        },
        {
          label: "Sales in 2024",
          data: sales2024,
          borderColor: "red",
          backgroundColor: "rgba(255, 0, 0, 0.2)",
          fill: true,
        },
      ],
    };

    return (
      <div className="card-comparision">
        <h3>Monthly Orders Comparison (2025 vs 2024)</h3>
        <Line data={chartData} />
        <ul>
          {months.map((month, index) => (
            <li key={month}>
              {month}: {percentageChange[index] > 0 ? `🔼` : `🔽`}{" "}
              {percentageChange[index]}%
            </li>
          ))}
        </ul>
      </div>
    );
  };

  calculateProductionCostPercentage(meal) {
    const buildCost = this.calculateBuildCost(meal);

    // Validate meal price to avoid division by zero or undefined
    if (!meal.base_meal__price || meal.base_meal__price === 0) {
      return "N/A"; // Return "N/A" if meal price is invalid
    }

    const productionCostPercentage = (buildCost / meal.base_meal__price) * 100;
    return productionCostPercentage.toFixed(2); // Return percentage with 2 decimal places
  }

  renderTop10Items = () => {
    const { dashboardData } = this.state;
    if (!dashboardData || !dashboardData.qs_top_10_selling_item) return null;

    return (
      <div className="card styled-list">
        <h3>Top 10 Selling Items This Year</h3>
        {this.renderTopSellingItemsChart()}
        <ul>
          {dashboardData.qs_top_10_selling_item.map((item, index) => {
            const buildCost = this.calculateBuildCost(item);
            const productionCostPercentage = this.calculateProductionCostPercentage(
              item
            );

            return (
              <li key={index} className="list-item">
                <span className="rank">#{index + 1}</span>
                <span className="item-name">{item.event_meal__name}</span>
                <span className="quantity">
                  Total Sold: {item.total_quantity}
                </span>
                <span className="cost">
                  Build Cost: ${buildCost.toFixed(2)}
                </span>
                <span className="percentage">
                  Production Cost: {productionCostPercentage}%
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  renderTop10ItemsThisWeek = () => {
    const { dashboardData } = this.state;
    if (!dashboardData || !dashboardData.top_10_items_week) return null;

    return (
      <div className="card styled-list">
        <h3>Top 10 Selling Items This Week</h3>
        {this.renderTopSellingItemsWeeklyChart()}
        <ul>
          {dashboardData.top_10_items_week.map((item, index) => (
            <li key={index} className="list-item">
              <span className="rank">#{index + 1}</span>
              <span className="item-name">{item.event_meal__name}</span>
              <span className="quantity">{item.total_quantity} </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  handleShowModal = (content) => {
    this.setState({ showModal: true, modalContent: content });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, modalContent: null });
  };

  //   render eoi tabel

  renderEOITable = () => {
    const { dashboardData } = this.state;
    if (!dashboardData) return null;

    const {
      pending_applications,
      accepted_eoi,
      rejected_eoi,
      share_a_link,
    } = dashboardData;

    // Merge the data
    const mergedEOIs = [
      ...pending_applications.map((eoi) => ({
        name: eoi.eoi_id__eoi_event_name,
        status: "Pending",
        count: eoi.pending_eoi,
      })),
      ...accepted_eoi.map((eoi) => ({
        name: eoi.eoi_id__eoi_event_name,
        status: "Accepted",
        count: eoi.accepted_eoi,
      })),
      ...rejected_eoi.map((eoi) => ({
        name: eoi.eoi_id__eoi_event_name,
        status: "Rejected",
        count: eoi.rejected_eoi,
      })),
      ...share_a_link.map((link) => ({
        name: link.event_name,
        status: link.status,
        count: 1, // Each occurrence in `share_a_link` counts as 1
      })),
    ];
    this.setState({ mergedEOIS: mergedEOIs.length });

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Status</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {mergedEOIs.map((eoi, index) => (
            <tr key={index}>
              <td>{eoi.name}</td>
              <td>{eoi.status}</td>
              <td>{eoi.count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderTopSellingItemsChart = () => {
    const { dashboardData } = this.state;
    if (!dashboardData || !dashboardData.qs_top_10_selling_item) return null;

    const labels = dashboardData.qs_top_10_selling_item.map(
      (item) => item.event_meal__name
    );
    const data = dashboardData.qs_top_10_selling_item.map(
      (item) => item.total_quantity
    );

    const chartData = {
      labels,
      datasets: [
        {
          label: "Top 10 Selling Items",
          data,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
            "#FFCD94",
            "#C9CBCF",
            "#36C1A5",
            "#FF6684",
          ],
        },
      ],
    };

    return <Pie data={chartData} />;
  };

  renderTopSellingItemsWeeklyChart = () => {
    const { dashboardData } = this.state;
    if (!dashboardData || !dashboardData.top_10_items_week) return null;

    const labels = dashboardData.top_10_items_week.map(
      (item) => item.event_meal__name
    );
    const data = dashboardData.top_10_items_week.map(
      (item) => item.total_quantity
    );

    const chartData = {
      labels,
      datasets: [
        {
          label: "Top 10 Selling Items Weekly",
          data,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
            "#FFCD94",
            "#C9CBCF",
            "#36C1A5",
            "#FF6684",
          ],
        },
      ],
    };

    return <Pie data={chartData} />;
  };

  handleFilterChange = (filter) => {
    this.setState({ selectedFilter: filter }, () => {
      this.fetchDashboardData(filter);
    });
  };

  renderSalesData = () => {
    const { yearly_sales, weekly_sales } = this.props;

    const { selectedFilter, dashboardData } = this.state;

    const currentYear = moment().format("YYYY");
    const currentWeek = moment().format("[Week] W [of] YYYY");
    // Calculate the difference and percentage change
    const salesDifference =
      dashboardData.weekly_sales.current_week_sales -
      dashboardData.last_week_sales.last_week_sales;
    const salesPercentageChange = (
      (salesDifference / dashboardData.last_week_sales.last_week_sales) *
      100
    ).toFixed(2);
    const isIncrease = salesDifference > 0;

    const salesDifferenceLastYear =
      dashboardData.yearly_sales.current_year_sales -
      dashboardData.last_year_sales.last_week_sales;
    const salesPercentageChangeLastYear = (
      (salesDifferenceLastYear /
        dashboardData.last_year_sales.last_week_sales) *
      100
    ).toFixed(2);
    const isIncreaseLastYear = salesDifferenceLastYear > 0;
    debugger;

    return selectedFilter === "Yearly" ? (
      <div className="card">
        <h3>Live Yearly Sales ({currentYear})</h3>
        <p>
          Sales: $
          {dashboardData.yearly_sales.current_year_sales != null
            ? dashboardData.yearly_sales.current_year_sales.toLocaleString()
            : 0}
        </p>
        <p>
          Orders:{" "}
          {dashboardData.yearly_sales.current_year_sales != null
            ? dashboardData.yearly_sales.current_year_order_count
            : 0}
        </p>
        <p>
          {isIncreaseLastYear ? (
            <span style={{ color: "green" }}>
              ▲ Up by in comparison to last year $
              {salesDifferenceLastYear.toFixed(2)} (
              {salesPercentageChangeLastYear}%)
            </span>
          ) : (
            <span style={{ color: "red" }}>
              ▼ Down by in comparison to last year $
              {Math.abs(salesDifferenceLastYear).toFixed(2)} (
              {Math.abs(salesPercentageChangeLastYear)}%)
            </span>
          )}
        </p>
      </div>
    ) : (
      <div className="card">
        <h3>Live Weekly Sales ({currentWeek})</h3>
        <p>
          Sales: $
          {dashboardData.weekly_sales.current_week_sales != null
            ? dashboardData.weekly_sales.current_week_sales.toLocaleString()
            : "0"}
        </p>
        <p>
          Orders:{" "}
          {dashboardData.weekly_sales.current_week_order_count != null
            ? dashboardData.weekly_sales.current_week_order_count
            : 0}
        </p>
        <p>
          {isIncrease ? (
            <span style={{ color: "green" }}>
              ▲ Up by in comparison to last year same week $
              {salesDifference.toFixed(2)} ({salesPercentageChange}%)
            </span>
          ) : (
            <span style={{ color: "red" }}>
              ▼ Down by in comparison to last year same week $
              {Math.abs(salesDifference).toFixed(2)} (
              {Math.abs(salesPercentageChange)}%)
            </span>
          )}
        </p>
      </div>
    );
  };

  // Function to handle the "Know More" button action
  handleKnowMore = (tool) => {
    const operatorId = JSON.parse(getFromLocalStorage("Operator"))[0].id;
    const timestamp = moment().format("DD-MM-YYYY");
    const url = `${config.BASE_URI}${apiPaths.operatorData}`;
    const headers = new Headers({ "Content-Type": "application/json" });

    const body = JSON.stringify({
      payload: {
        body: {
          query_type: "vendor_information",
          operator_id: operatorId,
          timestamp: timestamp,
          tool: tool, // Either "workforce" or "stock"
        },
      },
    });

    fetch(url, { method: "POST", headers, body })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({
            modalContent: <p>{tool} information successfully requested.</p>,
            showModal: true,
          });
        } else {
          throw new Error("Failed to fetch vendor information");
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalContent: <p>Failed to request {tool} information.</p>,
          showModal: true,
        });
      });
  };

  renderDashboard = () => {
    const { dashboardData, filter, selectedFilter } = this.state;

    if (!dashboardData) return null;

    const {
      yearly_sales,
      weekly_sales,
      upcoming_events,
      upcoming_runsheets,
      active_eoi,
      pending_applications,
      accepted_eoi,
      rejected_eoi,
      stalls_count,
      total_payout,
      total_wage,
      total_stock_cost,
      last_week_sales,
      qs_top_10_selling_item,
      last_year_sales,
      qs_events_participated_in,
      qs_total_payout,
    } = dashboardData;
    const currentYear = moment().format("YYYY");
    const currentWeek = moment().format("[Week] W [of] YYYY");
    // Calculate the difference and percentage change
    const salesDifference =
      weekly_sales.current_week_sales - last_week_sales.last_week_sales;
    const salesPercentageChange = (
      (salesDifference / last_week_sales.last_week_sales) *
      100
    ).toFixed(2);
    const isIncrease = salesDifference > 0;

    const salesDifferenceLastYear =
      yearly_sales.current_year_sales - last_year_sales.last_week_sales;
    const salesPercentageChangeLastYear = (
      (salesDifferenceLastYear / last_year_sales.last_week_sales) *
      100
    ).toFixed(2);
    const isIncreaseLastYear = salesDifferenceLastYear > 0;

    return (
      <>
        {this.renderComparisonGraph()}
        <div className="dashboard-grid-container">
          {/* <div className="overview-grid-main"></div> */}
          <div className="overview-grid">
            {/* <div className="card">
          <h3>Live Yearly Sales ({currentYear})</h3>
          <p>Sales: ${yearly_sales.current_year_sales.toLocaleString()}</p>
          <p>Orders: {yearly_sales.current_year_order_count}</p>

          <p>
            {isIncreaseLastYear ? (
              <span style={{ color: "green" }}>
                ▲ Up by in comparrision to last year ${salesDifferenceLastYear.toFixed(2)} ({salesPercentageChangeLastYear}%)
              </span>
            ) : (
              <span style={{ color: "red" }}>
                ▼ Down by in comparrision to last year  ${Math.abs(salesDifferenceLastYear).toFixed(2)} (
                {Math.abs(salesPercentageChangeLastYear)}%)
              </span>
            )}
          </p>
        </div> */}
            {/* <div className="card">
          <h3>Live Weekly Sales ({currentWeek})</h3>
          <p>
  Sales: $
  {weekly_sales.current_week_sales != null
    ? weekly_sales.current_week_sales.toLocaleString()
    : "N/A"}
</p>
          <p>Orders: {weekly_sales.current_week_order_count}</p>
          <p>
            {isIncrease ? (
              <span style={{ color: "green" }}>
                ▲ Up by in comparrision to last year same week ${salesDifference.toFixed(2)} ({salesPercentageChange}%)
              </span>
            ) : (
              <span style={{ color: "red" }}>
                ▼ Down by in comparrision to last year same week  ${Math.abs(salesDifference).toFixed(2)} (
                {Math.abs(salesPercentageChange)}%)
              </span>
            )}
          </p>
        </div> */}

            {this.renderSalesData()}

            <div className="card">
              <h3>Total Events Participated ({currentYear})</h3>
              <p>{qs_events_participated_in.length}</p>

              <Button
                variant="primary"
                onClick={() =>
                  this.handleShowModal(
                    <ul>
                      {qs_events_participated_in.map((app, index) => (
                        <li key={index}>{app.name}:</li>
                      ))}
                    </ul>
                  )
                }
              >
                See Details
              </Button>
            </div>

            <div className="card">
              <h3>
                EOI Applications {this.state.mergedEOIS} {currentYear}
              </h3>
              <Button
                variant="primary"
                onClick={() => this.handleShowModal(this.renderEOITable())}
              >
                View All EOIs
              </Button>
            </div>

            {this.state.selectedFilter === "Yearly"
              ? this.renderTop10Items()
              : this.renderTop10ItemsThisWeek()}
            <div className="card">
              <h3> Total Stalls on Wtt Operator Portal</h3>
              <p>{stalls_count}</p>
            </div>
            <div className="card">
              <h3>Total Payout ({currentYear})</h3>
              <p>
                $
                {qs_total_payout.total_payout != null
                  ? qs_total_payout.total_payout.toLocaleString()
                  : 0}
              </p>
            </div>
            {/* Add the new comparison graph here */}

            {total_wage === "not using" && (
              <div className="card">
                <h3>Total Wage</h3>
                <p>Not Calculated</p>
                <Button
                  variant="primary"
                  onClick={() => this.handleKnowMore("workforce")}
                >
                  Know More
                </Button>
              </div>
            )}

            {total_stock_cost === "not using" && (
              <div className="card">
                <h3>Total Stock Cost</h3>
                <p>Not Calculated</p>
                <Button
                  variant="primary"
                  onClick={() => this.handleKnowMore("stock")}
                >
                  Know More
                </Button>
              </div>
            )}
          </div>

          <div className="details-grid">
            <div className="details-card">
              <h3>Upcoming Events ({currentWeek})</h3>
              <ul>
                {upcoming_events.length !== 0 ? (
                  upcoming_events.map((event, index) => (
                    <li key={index}>{event.name}</li>
                  ))
                ) : (
                  <li>No upcoming events</li>
                )}
              </ul>
            </div>

            <div className="details-card">
              <h3>Upcoming Runsheets </h3>
              <ul>
                {upcoming_runsheets.length !== 0 ? (
                  upcoming_runsheets.map((runsheet, index) => (
                    <li key={index}>{runsheet.event_name}</li>
                  ))
                ) : (
                  <li>No upcoming events</li>
                )}
              </ul>
            </div>

            <div className="details-card">
              <h3>Active EOIs</h3>
              <ul>
                {active_eoi.map((eoi, index) => (
                  <li key={index}>{eoi.eoi_event_name}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const {
      isLoading,
      error,
      showModal,
      modalContent,
      selectedFilter,
    } = this.state;

    return (
      <>
        <VendorNavBar />
        <div className="dashboard-wrapper">
          <h1 className="vendor-header-dashboard">Vendor Dashboard </h1>
          <ButtonGroup className="mb-3 custom-button-group">
            {["Yearly", "Weekly"].map((filter) => (
              <ToggleButton
                key={filter}
                type="radio"
                variant="outline-primary"
                value={filter}
                checked={selectedFilter === filter}
                onChange={() => this.handleFilterChange(filter)}
              >
                {filter}
              </ToggleButton>
            ))}
          </ButtonGroup>
          {isLoading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>}
          {this.renderDashboard()}
        </div>

        <Modal show={showModal} onHide={this.handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect()(OperatorDashboard);
