import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import * as Sentry from "@sentry/react";

import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import { getFromLocalStorage } from "../../store";

const SelectionCriteriaModal = ({ criteriaData, event_id }) => {
  console.log(criteriaData);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => setShowModal(true);

  const [editorState, setEditorState] = useState(() => {
    if (criteriaData) {
      const contentBlock = convertFromHTML(criteriaData);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });

  useEffect(() => {
    setEditorState(() => {
      console.log("EFFECT");
      if (criteriaData) {
        const contentBlock = convertFromHTML(criteriaData);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        return EditorState.createWithContent(contentState);
      }
      return EditorState.createEmpty();
    });
  }, [criteriaData]);

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
  };

  const handleSubmit = () => {
    const contentState = editorState.getCurrentContent();
    const rawState = convertToRaw(contentState);
    const html = draftToHtml(rawState);
    console.log(html);
    const organiserData =
      JSON.parse(getFromLocalStorage("Organiser")) &&
      JSON.parse(getFromLocalStorage("Organiser")).length
        ? JSON.parse(getFromLocalStorage("Organiser"))[0]
        : {};
    const url = config.BASE_URI + apiPaths.adminData;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "post_selection_criteria",
          selection_requirements: html,
          time_stamp: new Date(),
          event_id: event_id,
          selected_admin_user: organiserData.id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          alert("Selection Criteria updated Successfully!");
          setShowModal(false);
        } else alert("Something went wrong. Please try again");
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong. Please try again");
      });
  };

  const toolbar = {
    options: ["inline", "list"],
    inline: {
      options: ["bold"],
    },
    list: {
      options: ["unordered"],
    },
  };

  return (
    <>
      <Row>
        <Col></Col>
        <Col
          style={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            className="see-details-btn"
            style={{
              color: "#2959a5",
              cursor: "pointer",
            }}
            onClick={handleShow}
          >
            See Details
          </p>
        </Col>
        <Col></Col>
      </Row>
      <Modal fullscreen={true} show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Selection Criteria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Editor
            v
            editorClassName="criteria-editor-modal"
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbar}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectionCriteriaModal;
