import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import { Form, Container, Button, Row, Col, Modal } from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "./utils.js";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventSalesBarGraph } from "../EventOrganiserOverviewScreen/EventSalesBarGraph";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import SalesTable from "../AdminExternalOperatorsDashboardScreen/Components/SalesTabel";
import SalesByEventTabel from "../AdminExternalOperatorsDashboardScreen/Components/SalesByEventTabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";

class AdminSalesBySiteArea extends Component {
  state = {
    refundsModal: false,
    salesModal: false,
    ordersModal: false,
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    total_voucher_value: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorObj: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    salesByEventName: [],
    salesByEventTotal: [],
    salesByEventObj: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  handleShow = (modalName) => {
    this.setState({ [modalName]: true });
  };

  handleClose = (modalName) => {
    this.setState({ [modalName]: false });
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
              this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_figures_for_site_areas",
          email: email,
          password: password,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: dataResponse.card_new_count,
            totalCashOrders: dataResponse.cash_new_count,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor_obj,
            salesByEvent: dataResponse.sales_by_events_obj,
            salesByHours: dataResponse.sorted_hours_sales,
            salesByVendorObj: dataResponse.sales_by_vendor_obj,
            salesByEventObj: dataResponse.sales_by_events_obj,
            total_voucher_value: dataResponse.total_voucher_value,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        var salesBYEventName = [];
        var salesBYEventTotal = [];
        // alert("salesByVendor" + dataResponse.sales_by_vendor_obj)
        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor_obj
        )) {
          console.log(`${key}: ${salesBYVendorName.push(key)}`);
          console.log(`${value}: ${salesBYVendorTotal.push(value)}`);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });

        for (const [key, value] of Object.entries(
          dataResponse.sales_by_events_obj
        )) {
          console.log(`${key}: ${salesBYEventName.push(key)}`);
          console.log(`${value}: ${salesBYEventTotal.push(value)}`);
        }

        this.setState({ salesByEventName: salesBYEventName });
        this.setState({ salesByEventTotal: salesBYEventTotal });

        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
      selectedVendorId,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_site_areas",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.vendors }, () => {
            this.setState({ selectedVendorId: selectedVendorId }, () => {
              saveToLocalStorage("selectedVendorId", selectedVendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      total_voucher_value,
      selectedDate,
      startDate,
      endDate,
      salesByVendorObj,
      salesByEventObj,
    } = this.state;
    if (selectedEvent) {
    }

    let date_range = this.getDateRange(startDate, endDate);

    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} md={4} lg={4}>
                  {vendors && vendors.length > 0 ? (
                    <Form.Group
                      style={{ margin: 0 }}
                      controlId="exampleForm.SelectCustomSizeSm"
                    >
                      <Form.Label style={{ marginBottom: 24 }}>
                        Site Areas {"(" + vendors.length + ")"}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={selectedVendorId}
                        onChange={(e) => {
                          this.setState(
                            { selectedVendorId: "" + e.target.value },
                            () => {
                              // this.getOrders();
                            }
                          );
                          saveToLocalStorage(
                            "selectedVendorId",
                            "" + e.target.value
                          );
                        }}
                      >
                        {vendors && vendors.length == 1 ? (
                          <option value={vendors[0].id}>
                            {vendors[0].site_name}
                          </option>
                        ) : (
                          <>
                            <option value={0}>All Sites</option>
                            {vendors &&
                              vendors.map((e) => {
                                return (
                                  <option value={e.id}>{e.site_name}</option>
                                );
                              })}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  ) : null}
                </Col>

                <Col xs={12} md={4}>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    className="custom-select custom-select-sm"
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDate}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    className="custom-select custom-select-sm"
                    dateFormat={"dd/MM/yyyy"}
                    selected={endDate}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </Col>
                <Col style={{ display: "flex", alignItems: "start" }}>
                  <Button
                    style={{ marginTop: 30 }}
                    onClick={() => this.getOrders()}
                  >
                    Apply
                  </Button>
                </Col>

                {selectedVendorId != "0" ? (
                  <Col xs={12} md={3} lg={3}>
                    <Form.Label>Select Day</Form.Label>
                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={this.state.selectedDate}
                        onChange={(e) => {
                          this.setState(
                            { selectedDate: e.target.value },
                            () => {
                              this.getOrders();
                            }
                          );
                        }}
                      >
                        <>
                          <option value={"-1"}>All Days</option>
                          {date_range.map((e, zrindex) => {
                            return <option value={e}>{e}</option>;
                          })}
                        </>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                ) : null}
              </Row>

              <Row>
                <Col xs={12} md={4} lg={4}>
                  <h5 style={{ textAlign: "center" }}>
                    Total Sales: ${Number(total_sales).toFixed(2)}
                  </h5>{" "}
                  <p
                    className="see-details-btn"
                    style={{
                      color: "#2959a5",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => this.handleShow("salesModal")}
                  >
                    See Details
                  </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <h5 style={{ textAlign: "center" }}>
                    Total Orders: {total_orders}
                  </h5>{" "}
                  <p
                    className="see-details-btn"
                    style={{
                      color: "#2959a5",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => this.handleShow("ordersModal")}
                  >
                    See Details
                  </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <h5 style={{ textAlign: "center" }}>
                    Refunds: ${Number(canceled_sales).toFixed(2)}
                  </h5>{" "}
                  <p
                    className="see-details-btn"
                    style={{
                      color: "#2959a5",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => this.handleShow("refundsModal")}
                  >
                    See Details
                  </p>
                </Col>
              </Row>
              <Modal
                show={this.state.salesModal}
                onHide={() => this.handleClose("salesModal")}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Total Cash Sales: ${Number(totalCash).toFixed(2)}</p>
                  <p>
                    Total Card Sales: $
                    {Number(totalCard).toFixed(2) + "/" + totalCardOrders}
                  </p>
                  <p>
                    Total Voucher Values: $
                    {Number(
                      Number(total_voucher_value) - Number(canceled_sales_card)
                    ).toFixed(2)}
                  </p>
                </Modal.Body>
              </Modal>

              <Modal
                show={this.state.ordersModal}
                onHide={() => this.handleClose("ordersModal")}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Orders Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Total Cash Orders:
                    {totalCashOrders}
                  </p>
                  <p>
                    Total Card Orders:
                    {totalCardOrders}
                  </p>
                  <p>
                    Total Orders Less Refunded Orders:
                    {total_orders - canceled_orders}
                  </p>
                </Modal.Body>
              </Modal>

              <Modal
                show={this.state.refundsModal}
                onHide={() => this.handleClose("refundsModal")}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Refund Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Total Sales Less Refund: $
                    {Number(
                      Number(total_sales) - Number(canceled_sales)
                    ).toFixed(2)}
                  </p>
                  <p>
                    Total Cash Less Refund: $
                    {Number(
                      Number(totalCash) - Number(canceled_sales_cash)
                    ).toFixed(2)}
                  </p>
                  <p>
                    Total Card Less Refund: $
                    {Number(
                      Number(totalCard) - Number(canceled_sales_card)
                    ).toFixed(2)}
                  </p>
                </Modal.Body>
              </Modal>

              <hr />

              <div className="container-accordion">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <strong>SALES BY VENDORS</strong>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SalesTable
                      sales_by_vendor_obj={salesByVendorObj}
                    ></SalesTable>
                    <EventOrganiserBarGraph
                      vendorName={this.state.salesByVendorName}
                      vendorData={this.state.salesByVendorTotal}
                      isBool={true}
                    ></EventOrganiserBarGraph>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="container-accordion">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <strong>SALES BY EVENTS</strong>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SalesByEventTabel
                      sales_by_vendor_obj={salesByEventObj}
                    ></SalesByEventTabel>
                    <EventSalesBarGraph
                      vendorName={this.state.salesByEventName}
                      vendorData={this.state.salesByEventTotal}
                      isBool={true}
                    ></EventSalesBarGraph>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminSalesBySiteArea, {
    fallback: <ErrorFallbackComponent />,
  })
);
