import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Alert, Button, Form } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import "./style.css";
import * as Sentry from "@sentry/react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Footer from "../../components/UI/Footer";

const AdminLoginScreen = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const validateForm = () => {
    return email.length > 0 && password.length > 0 && captchaValue !== null;
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!captchaValue) {
      // CAPTCHA not completed
      return;
    }

    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (
          dataResponse.success === true &&
          dataResponse.data[0].is_super_admin === true
        ) {
          saveToLocalStorage("isSuperAdminLoggedIn", true);
          saveToLocalStorage("email", email);
          saveToLocalStorage("password", password);
          window.location.href = "/admin-dashboard";
        } else if (
          dataResponse.success === true &&
          dataResponse.data[0].is_event_coordinator === true
        ) {
          saveToLocalStorage("isEventCoordinatorLoggedIn", true);
          saveToLocalStorage("email", email);
          saveToLocalStorage("password", password);
          window.location.href = "/admin-dashboard";
        } else if (
          dataResponse.success === true &&
          dataResponse.data[0].is_accountant === true
        ) {
          saveToLocalStorage("isAccountantLoggedIn", true);
          saveToLocalStorage("email", email);
          saveToLocalStorage("password", password);
          window.location.href = "/admin-orders";
        } else if (
          dataResponse.success === true &&
          dataResponse.data[0].is_stock_admin === true
        ) {
          saveToLocalStorage("isStockAdminLoggedIn", true);
          saveToLocalStorage("email", email);
          saveToLocalStorage("password", password);
          window.location.href = "/stock-admin-approved-orders";
        } else {
          setInvalid(true);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f8f8f8",
          minHeight: `${screenSize.dynamicHeight}px`,
        }}
      >
        <Navbar
          variant="dark"
          expand="lg"
          style={{ backgroundColor: "#2859a5", marginBottom: "20px" }}
        >
          <Navbar.Brand href="/operator-landing">
            <Image
              style={{ height: 40 }}
              src={require("../../assets/img/welcome-logo.png")}
              fluid
            />
          </Navbar.Brand>
        </Navbar>

        <Container>
          <div>
            <Alert variant="danger" show={invalid}>
              Invalid email/password. Please try again.
            </Alert>

            <div className="login-organiser">
              {getFromLocalStorage("isEventCoordinatorLoggedIn") === "true" && (
                <Redirect to="/admin-dashboard" />
              )}
              {getFromLocalStorage("isSuperAdminLoggedIn") === "true" && (
                <Redirect to="/admin-dashboard" />
              )}
              {getFromLocalStorage("isAccountantLoggedIn") === "true" && (
                <Redirect to="/admin-txn-report" />
              )}
              {getFromLocalStorage("isStockManagerLoggedIn") === "true" && (
                <Redirect to="/stock-admin-approved-orders" />
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-6" size="lg" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    autoFocus
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Label>Password</Form.Label>
                <OutlinedInput
                  placeholder="Password"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  style={{ width: "100%", height: "50%" }}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <a
                  onClick={() => {
                    saveToLocalStorage("LOGIN_ROUTE", window.location.pathname);
                  }}
                  href="/forgot-password"
                  className="_97w4"
                  target=""
                >
                  Forgot Password?
                </a>
                <ReCAPTCHA
                  style={{ marginTop: 20 }}
                  sitekey="6Leq8AskAAAAAD-JPvpPqtR6vgeBCq_fajZvghoJ"
                  onChange={handleCaptchaChange}
                />

                <Button
                  block
                  size="lg"
                  type="submit"
                  disabled={!validateForm()}
                  style={{ marginTop: 20 }}
                >
                  Login
                </Button>
              </Form>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default AdminLoginScreen;
