import React from "react";
import "./style.css";

const DashboardCard = ({ children, title, background, show, style, link }) => {
  let styles = {
    background: background !== "dark" ? "white" : "#f3f1ef",
  };
  if (style) styles = { ...styles, ...style };
  return show || show === undefined ? (
    <div className="dashboard-card" style={styles}>
      <h3 className="card-heading">{title}</h3>
      <div className="content-container">{children}</div>
      {link ? (
        <div className="dashboard-footer">
          <a href={link}>View Details</a>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default DashboardCard;
