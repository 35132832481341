import React, { useState, useEffect } from "react";
import { Container, Card, Form, Button, Alert } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import moment from "moment";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import "./style.css";



function RetailStallApplication() {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    businessName: "",
    stallName: "",
    productType: "",
    stallHolderBio: "",
    abnNumber: "",
    stallHolderFullName: "",
    stallHolderEmail: "",
    phoneNumber: "",
    liabilityCertificate: null,
    logo: null,
    agreeToTerms: false,
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetch(`${config.BASE_URI}${apiPaths.retailEoi}`)
      .then((response) => response.json())
      .then((data) => {
        setDetails(data.eoi_details || null);
        setLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Error fetching details:", error);
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    }
  };


  const validateForm = () => {
    let newErrors = {};
    if (!formData.businessName) newErrors.businessName = "Business Name is required";
    if (!formData.stallName) newErrors.stallName = "Stall Name is required";
    if (!formData.productType) newErrors.productType = "Product Type is required";
    if (!formData.stallHolderBio) newErrors.stallHolderBio = "Bio is required";
    if (!formData.abnNumber) newErrors.abnNumber = "ABN Number is required";
    if (!formData.stallHolderFullName) newErrors.stallHolderFullName = "Stall Holder Name is required";
    if (!formData.stallHolderEmail) newErrors.stallHolderEmail = "Email is required";
    if (!formData.phoneNumber || formData.phoneNumber.length !== 10) newErrors.phoneNumber = "Phone number must be 10 digits";
    if (!formData.liabilityCertificate) newErrors.liabilityCertificate = "Liability Certificate is required";
    if (!formData.logo) newErrors.logo = "Logo is required";
    if (!formData.agreeToTerms) newErrors.agreeToTerms = "You must agree to the terms";
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    console.log(e)
    e.preventDefault();
    if (!validateForm()) return; // Ensure validation runs
  
    const formDataObj = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] instanceof File) {
        formDataObj.append(key, formData[key]);
      } else {
        formDataObj.append(key, formData[key]?.toString() || "");
      }
    });
  
    fetch(`${config.BASE_URI}${apiPaths.postStallHolderApplications}`, {
      method: "POST",
      body: formDataObj,
    })
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        setSuccessMessage("Application submitted successfully!");
        alert("Application submitted successfully!");
        setFormData({
          businessName: "",
          stallName: "",
          productType: "",
          stallHolderBio: "",
          abnNumber: "",
          stallHolderFullName: "",
          stallHolderEmail: "",
          phoneNumber: "",
          liabilityCertificate: null,
          logo: null,
          agreeToTerms: false,
        });
        setErrors({});
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Error submitting application:", error);
      });
  };



  if (loading) return <div className="text-center mt-5">Loading EOI details...</div>;
  if (!details) return <div className="text-center mt-5">No EOI details available.</div>;


  return (
    <Container className="retail-stall-application mt-5">
      <h1 className="text-center">Retail Stall Holder Application</h1>

      <Card className="mb-4 shadow-sm">
        {details.eoi_cover && (
          <Card.Img variant="top" src={details.eoi_cover} style={{ 
    height: "200px",  // Adjust height as needed
    objectFit: "cover",  // Ensures the image fills the space without stretching
    borderRadius: "5px" // Adds a slight rounded edge for aesthetics
  }}  alt="EOI Cover" />
        )}

        <Card.Body>
          <h3 className="text-primary">{details.eoi_event_name}</h3>
          <p><strong>Address:</strong> {details.site_area}</p>
          
          <p><strong>Services:</strong> {details.services}</p>

          <h5 className="mt-4">Event Schedule</h5>
          <p><strong>Bump In:</strong> {moment(details.bump_in_date_time).format("DD MM YYYY")}</p>
          <p><strong>Bump Out:</strong> {moment(details.bump_out_date_time).format("DD MM YYYY")}</p>
          <p><strong>Event Start:</strong> {moment(details.event_start_date_time).format("DD MM YYYY")}</p>
          <p><strong>Event End:</strong> {moment(details.event_end_date_time).format("DD MM YYYY")}</p>
         

          <h5 className="mt-4">Organizer Details</h5>
          <p><strong>Name:</strong> {details.event_organiser_first_name} {details.event_organiser_last_name}</p>
          <p><strong>Email:</strong> {details.eoi_email_id}</p>

          <h5 className="mt-4">Financial Details</h5>
          <p><strong>Site Fees:</strong> ${details.site_fees}</p>
          <p><strong>Bond (Refundable):</strong> ${details.bond_amount}</p>

          {/* <h5 className="mt-4">Electricity Cost</h5>
          <p><strong>10 Amp:</strong> ${details.event_number_of_10_amp}</p>
          <p><strong>15 Amp:</strong> ${details.event_number_of_15_amp}</p>
          <p><strong>20 Amp:</strong> ${details.event_number_of_20_amp}</p>
          <p><strong>32 Amp:</strong> ${details.event_number_of_32_amp}</p> */}

          <h5 className="mt-4">Event Description</h5>
          <p>{details.eoi_description}</p>

          {details.event_terms_and_conditions && (
            <p>
              <a href={details.event_terms_and_conditions} target="_blank" rel="noopener noreferrer">
                View Terms and Conditions
              </a>
            </p>
          )}
        </Card.Body>
      </Card>

      {successMessage && (
        <Alert variant="success" className="text-center">
          <FaCheckCircle className="me-2" />
          {successMessage}
        </Alert>
      )}

      <Form onSubmit={handleSubmit} className="p-4 shadow-sm bg-light rounded">
        <Form.Group className="mb-3">
          <Form.Label>Business Name</Form.Label>
          <Form.Control type="text" name="businessName" value={formData.businessName} onChange={handleChange} isInvalid={!!errors.businessName} />
          <Form.Control.Feedback type="invalid">{errors.businessName}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Stall Name</Form.Label>
          <Form.Control type="text" name="stallName" value={formData.stallName} onChange={handleChange} isInvalid={!!errors.stallName} />
          <Form.Control.Feedback type="invalid">{errors.stallName}</Form.Control.Feedback>
       
        <Form.Group className="mb-3"></Form.Group>
        <Form.Label>Product Type</Form.Label>
        <Form.Control 
  type="text" 
  name="productType" 
  value={formData.productType} // Fix: Incorrect binding before
  onChange={handleChange} 
  isInvalid={!!errors.productType} 
/>
</Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Bio</Form.Label>
          <Form.Control type="text" name="stallHolderBio" value={formData.stallHolderBio} onChange={handleChange} isInvalid={!!errors.stallHolderBio} />
          <Form.Control.Feedback type="invalid">{errors.stallHolderBio}</Form.Control.Feedback>
        </Form.Group>


        <Form.Group className="mb-3">
          <Form.Label>ABN Number</Form.Label>
          <Form.Control type="text" name="abnNumber" value={formData.abnNumber} onChange={handleChange} isInvalid={!!errors.abnNumber} />
          <Form.Control.Feedback type="invalid">{errors.abnNumber}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Stall Holder Name</Form.Label>
          <Form.Control type="text" name="stallHolderFullName" value={formData.stallHolderFullName} onChange={handleChange} isInvalid={!!errors.stallHolderFullName} />
          <Form.Control.Feedback type="invalid">{errors.stallHolderFullName}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Stall Holder Email </Form.Label>
          <Form.Control type="text" name="stallHolderEmail" value={formData.stallHolderEmail} onChange={handleChange} isInvalid={!!errors.stallHolderEmail} />
          <Form.Control.Feedback type="invalid">{errors.stallHolderEmail}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="phoneNumber" className="mt-3">
          <Form.Label>Phone Number (AU - 10 digits)</Form.Label>
          <Form.Control type="number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} isInvalid={!!errors.phoneNumber} />
          <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="liabilityCertificate" className="mt-3">
          <Form.Label>Public Liability Certificate</Form.Label>
          <Form.Control type="file" name="liabilityCertificate" onChange={handleFileChange} isInvalid={!!errors.liabilityCertificate} />
          <Form.Control.Feedback type="invalid">{errors.liabilityCertificate}</Form.Control.Feedback>
        </Form.Group>


        <Form.Group controlId="logo" className="mt-3">
          <Form.Label>Business Logo</Form.Label>
          <Form.Control type="file" name="logo" onChange={handleFileChange} isInvalid={!!errors.logo} />
          <Form.Control.Feedback type="invalid">{errors.logo}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="agreeToTerms" className="mt-3">
          <Form.Check type="checkbox" label="I agree to the terms and conditions" name="agreeToTerms" checked={formData.agreeToTerms} onChange={handleChange} isInvalid={!!errors.agreeToTerms} />
        </Form.Group>

        <Button type="submit" variant="primary" className="w-100">Submit Application</Button>
      </Form>
    </Container>
  );
}

export default Sentry.withErrorBoundary(RetailStallApplication, { fallback: <ErrorFallbackComponent /> });
