import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import CloseIcon from "@mui/icons-material/Close";
import VendorMenuAddScreen from "../VendorMenuAddScreen";
import VendorMenuEditScreen from "../VendorMenuEditScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import { withRouter } from "react-router-dom";

const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

class OperatorMenuScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operator_id: null,
      isLoading: false,
      tempMenuDetails: [],
      showVendorMenuAddScreen: false,
      showVendorMenuEditScreen: false,
      mealProp: [],
      menuDetails: [],
      isLoading: false,
      selectedBrandMeals: [],
      tempSelectedBrandMeals: [],
      selectedVendorId: -1,
      selectedVendorName: "",
    };
    this.abortController = new AbortController();
  }
  getTruckParam = () => {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);

    // Get specific param
    const truckId = queryParams.get("truck"); // e.g., ?key=value => "value"
    console.log({ truckId });
    return truckId;
  };
  setQueryParams = (truckId) => {
    const { history } = this.props;

    // Create new query params
    const queryParams = new URLSearchParams();
    if (truckId) {
      queryParams.set("truck", truckId);

      // Update URL with new query string
      history.push({
        pathname: "/apply-events/events-menu", // Current or target path
        search: `?${queryParams.toString()}`, // e.g., "?key=newValue&foo=bar"
      });
    }
  };

  componentDidMount() {
    this.getMenu();
    this.getTruckParam();
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  calculateBuildCost(meal) {
    let totalBuildCost = 0;

    meal.build_chart.forEach((item) => {
      let cost = 0;

      if (
        item.stocklist__measurment_type === null &&
        item.stocklist__weight_value > 1
      ) {
        // If measurement type is null and weight value is greater than 1
        cost =
          (item.stocklist__weight_value / item.stocklist__price) *
          item.quantity_per_meal;
      } else if (
        !item.stocklist__weight_value ||
        !item.stocklist__measurment_type
      ) {
        // If weight value or measurement type is missing, treat as a unit
        cost = item.stocklist__price * item.quantity_per_meal;
      } else if (
        item.stocklist__price > 0 &&
        item.stocklist__weight_value > 0
      ) {
        let adjustedWeightValue =
          item.stocklist__weight_value > 1 ? item.stocklist__weight_value : 1;

        if (item.unit === "Grams") {
          let totalWeightInGrams =
            item.stocklist__measurment_type === "Kilograms"
              ? adjustedWeightValue * 1000
              : adjustedWeightValue;

          let costPerGram = item.stocklist__price / totalWeightInGrams;
          cost = costPerGram * item.quantity_per_meal;
        } else if (item.unit === null) {
          cost = item.stocklist__price / adjustedWeightValue;
        }
      }

      totalBuildCost += cost;
    });

    return totalBuildCost;
  }

  calculateProductionCostPercentage(meal) {
    const buildCost = this.calculateBuildCost(meal);
    const productionCostPercentage = (buildCost / meal.meal_price) * 100;
    return productionCostPercentage.toFixed(2); // Return as percentage
  }

  getMenu() {
    const { operator_id } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_menu_for_operator_stalls",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      signal: this.abortController.signal,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
          if (dataResponse.success) {
            console.log(
              dataResponse.menu_details.map((item) => item.build_chart)
            );
            this.setState({
              menuDetails: dataResponse.menu_details,
              tempMenuDetails: dataResponse.menu_details,
            });
            const currentTruckId = this.getTruckParam();
            if (currentTruckId) {
              const truck = dataResponse.menu_details.find(
                (item) => item.stalls__id.toString() == currentTruckId
              );
              if (truck) {
                const tempMeals = [...truck.meals];
                this.setState(
                  {
                    selectedBrandMeals: [...tempMeals],
                    tempSelectedBrandMeals: truck.meals,
                    selectedVendorId: truck.stalls__id,
                    selectedVendorName: truck.title,
                    showVendorMenuAddScreen: false,
                    showVendorMenuEditScreen: false,
                  },
                  () => {
                    saveToLocalStorage("selectedVendorId", truck.stalls__id);
                  }
                );
              }
            }
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
        }
      });
  }
  render() {
    const {
      menuDetails,
      selectedBrandMeals,
      isLoading,
      selectedVendorId,
      selectedVendorName,
    } = this.state;
    return (
      <div>
        <VendorNavBar />

        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          {isLoading == false ? (
            <Row>
              <Col
                xs={12}
                md={3}
                lg={3}
                style={{
                  backgroundColor: "#f1f1f1",
                  borderRightWidth: 1,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderStyle: "solid",
                  borderColor: "black",
                  padding: 0,
                }}
              >
                <div>
                  <h3
                    style={{
                      padding: 20,
                      borderBottomWidth: 1,
                      borderTopWidth: 1,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderColor: "black",
                      borderStyle: "solid",
                    }}
                  >
                    Trucks
                  </h3>
                </div>
                {menuDetails &&
                  menuDetails.map((item, index) => {
                    return (
                      <div
                        key={item.stalls__id}
                        style={
                          selectedVendorId == item.stalls__id
                            ? {
                                backgroundColor: "white",
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                            : {
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                        }
                        onClick={() => {
                          const tempMeals = [...item.meals];
                          // tempMeals[0].build_chart = [
                          //   {
                          //     stocklist__weight_value: 20,
                          //     stocklist__measurment_type: "grams",
                          //     stocklist__price: 0.2,
                          //     quantity_per_meal: 20,
                          //     unit: "Grams",
                          //     id: 1,
                          //     stocklist__name: "Cheese",
                          //   },

                          //   {
                          //     stocklist__weight_value: 20,
                          //     stocklist__measurment_type: "grams",
                          //     stocklist__price: 10.2,
                          //     quantity_per_meal: 20,
                          //     unit: "Grams",
                          //     id: 2,
                          //     stocklist__name: "Cheese 2",
                          //   },
                          //   {
                          //     stocklist__weight_value: 20,
                          //     stocklist__measurment_type: "grams",
                          //     stocklist__price: 14.5,
                          //     quantity_per_meal: 20,
                          //     unit: "Grams",
                          //     id: 3,
                          //     stocklist__name: "Cheese 3",
                          //   },
                          // ];
                          this.setQueryParams(item.stalls__id);

                          this.setState(
                            {
                              selectedBrandMeals: [...tempMeals],
                              tempSelectedBrandMeals: item.meals,
                              selectedVendorId: item.stalls__id,
                              selectedVendorName: item.title,
                              showVendorMenuAddScreen: false,
                              showVendorMenuEditScreen: false,
                            },
                            () => {
                              saveToLocalStorage(
                                "selectedVendorId",
                                item.stalls__id
                              );
                            }
                          );
                        }}
                      >
                        {item.stalls__title}
                      </div>
                    );
                  })}
              </Col>
              <Col xs={12} md={9} lg={9} className="mobile-padding">
                <Row className="mobile-only">
                  <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    <h4>{/* <b>selectedVendorName</b> */}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          this.onSearch(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={6} md={4} lg={4}>
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      onChange={(e) => {
                        this.onSort(e);
                      }}
                    >
                      <option value={1}>A - Z</option>
                      <option value={2}>Z - A</option>
                      <option value={3}>Status: Enabled - Disabled</option>
                      <option value={4}>Status: Disabled - Enabled</option>
                    </Form.Control>
                  </Col>
                  <Col xs={6} md={2} lg={2}>
                    <Button
                      style={{ margin: 10 }}
                      onClick={() => {
                        if (this.state.selectedVendorId == -1) {
                          alert(
                            "Please select ateleast 1 brand before adding the menu"
                          );
                        } else {
                          this.setState({ showVendorMenuAddScreen: true });
                        }
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                  <label>
                    Please add a list of items you wish to sell at this stall,
                    including category and price & Meal Images, Event Organisers
                    won't be able to accept your application should meal images
                    and description has not been filled{" "}
                  </label>
                </Row>
                {this.state.showVendorMenuAddScreen &&
                  (window.location.href =
                    "/operator-add-menu:" + this.state.selectedVendorId)}
                {this.state.showVendorMenuEditScreen &&
                  (() => {
                    saveToLocalStorage(
                      "editMeal",
                      JSON.stringify(this.state.mealProp)
                    );
                    console.log(this.state.mealProp);
                    window.location.href =
                      "/operator-edit-menu:" + this.state.selectedVendorId;
                    return null; // IIFE needs to return something as it's within JSX.
                  })()}

                {selectedBrandMeals && selectedBrandMeals.length > 0 ? (
                  <Row>
                    {selectedBrandMeals.map((meal, i) => (
                      <>
                        {meal.status === "Enabled" ? (
                          !meal.meal_image && meal.description ? (
                            <p style={{ color: "red", paddingLeft: "20px" }}>
                              * Meal image is missing
                            </p>
                          ) : !meal.description && meal.meal_image ? (
                            <p style={{ color: "red", paddingLeft: "20px" }}>
                              * Meal description is missing
                            </p>
                          ) : !meal.meal_image && !meal.description ? (
                            <p style={{ color: "red", paddingLeft: "20px" }}>
                              * Meal image and description are missing
                            </p>
                          ) : null
                        ) : null}
                        <Col xs={12} md={12} lg={12} key={i}>
                          <Media
                            style={{
                              padding: 10,
                              borderBottomWidth: 1,
                              borderTopWidth: 0,
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              borderColor: "black",
                              borderStyle: "solid",
                            }}
                          >
                            <Image
                              style={{ width: 120 }}
                              src={
                                meal.meal_image &&
                                meal.meal_image !== "" &&
                                meal.meal_image !== "NULL"
                                  ? AWS_URL + "/" + meal.meal_image
                                  : require("../../assets/img/empty_logo.png")
                              }
                              alt={meal.meal_name} // Adding alt for better accessibility
                            />
                            <Media.Body style={{ marginLeft: 10 }}>
                              <Row>
                                <Col xs={12} md={6} lg={6}>
                                  <h4>{meal.meal_name}</h4>
                                </Col>
                                <Col
                                  xs={6}
                                  md={2}
                                  lg={2}
                                  style={{ padding: 0 }}
                                >
                                  <h4>${Number(meal.meal_price).toFixed(2)}</h4>
                                </Col>
                                <Col xs={6} md={4} lg={4}>
                                  <h4>{meal.status}</h4>
                                </Col>
                              </Row>

                              {meal.meal_combo.length > 0 ? (
                                <div
                                  style={{
                                    paddingBottom: 10,
                                    borderBottomWidth: 1,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                    borderColor: "black",
                                    borderStyle: "solid",
                                  }}
                                >
                                  <p style={{ marginBottom: 0 }}>
                                    <b>Combos</b>
                                  </p>
                                  {meal.meal_combo.map((combo) => {
                                    return (
                                      <Row>
                                        <Col xs={12} md={6} lg={6}>
                                          {combo.name}
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={2}
                                          lg={2}
                                          style={{ padding: 0 }}
                                        >
                                          ${Number(combo.price).toFixed(2)}
                                        </Col>
                                        <Col xs={6} md={4} lg={4}>
                                          {combo.status}
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {meal.meal_subitem.length > 0 ? (
                                <div style={{ paddingBottom: 10 }}>
                                  <p style={{ marginBottom: 0 }}>
                                    <b>SubItem</b>
                                  </p>
                                  {meal.meal_subitem.map((subitem) => {
                                    return (
                                      <Row>
                                        <Col xs={12} md={6} lg={6}>
                                          {subitem.name}
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={2}
                                          lg={2}
                                          style={{ padding: 0 }}
                                        ></Col>
                                        <Col xs={6} md={4} lg={4}>
                                          {subitem.status}
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {meal.build_chart.length > 0 ? (
                                <div
                                  style={{
                                    paddingBottom: 10,
                                    borderBottomWidth: 1,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                    borderColor: "black",
                                    borderStyle: "solid",
                                  }}
                                >
                                  <p style={{ marginBottom: 0 }}>
                                    <b>Build Chart (Formula For Stock)</b>
                                  </p>
                                  {meal.build_chart.map((build_chart) => {
                                    // Calculate the cost for each item in the build chart
                                    let itemCost = 0;

                                    if (
                                      !build_chart.stocklist__weight_value ||
                                      !build_chart.stocklist__measurment_type
                                    ) {
                                      // If weight value or measurement type is missing, treat as a unit
                                      itemCost =
                                        build_chart.stocklist__price *
                                        build_chart.quantity_per_meal;
                                    } else if (
                                      build_chart.stocklist__price > 0 &&
                                      build_chart.stocklist__weight_value > 0
                                    ) {
                                      if (build_chart.unit === "Grams") {
                                        let totalWeightInGrams =
                                          build_chart.stocklist__measurment_type ===
                                          "Kilograms"
                                            ? build_chart.stocklist__weight_value *
                                              1000
                                            : build_chart.stocklist__weight_value;
                                        let costPerGram =
                                          build_chart.stocklist__price /
                                          totalWeightInGrams;
                                        itemCost =
                                          costPerGram *
                                          build_chart.quantity_per_meal;
                                      } else if (build_chart.unit === null) {
                                        itemCost = build_chart.stocklist__price;
                                      }
                                    }

                                    return (
                                      <Row key={build_chart.id}>
                                        <Col xs={12} md={6} lg={6}>
                                          {build_chart.stocklist__name}
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={2}
                                          lg={2}
                                          style={{ padding: 0 }}
                                        >
                                          {Number(
                                            build_chart.quantity_per_meal
                                          ).toFixed(2)}{" "}
                                          {build_chart.unit || "Each"}
                                        </Col>
                                        <Col xs={6} md={4} lg={4}>
                                          Cost: ${itemCost.toFixed(2)}
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>
                              ) : null}

                              <Row>
                                <Col xs={12} md={6} lg={6}>
                                  <h5>
                                    Build Cost: $
                                    {this.calculateBuildCost(meal).toFixed(2)}
                                  </h5>
                                </Col>
                                <Col xs={6} md={6} lg={6}>
                                  <h5>
                                    Production Cost %:{" "}
                                    {this.calculateProductionCostPercentage(
                                      meal
                                    )}
                                    %
                                  </h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={6} lg={6}></Col>
                                <Col
                                  xs={6}
                                  md={2}
                                  lg={2}
                                  style={{ padding: 0 }}
                                ></Col>
                                <Col xs={6} md={4} lg={4}>
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        showVendorMenuEditScreen: true,
                                        mealProp: { meal },
                                      });
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </Col>
                              </Row>
                            </Media.Body>
                          </Media>
                        </Col>
                      </>
                    ))}
                  </Row>
                ) : (
                  <div>No results</div>
                )}
              </Col>
            </Row>
          ) : (
            <p>Loading...</p>
          )}
        </Container>
      </div>
    );
  }
  onSearch = (e) => {
    let menuItems = this.state.tempSelectedBrandMeals;
    menuItems = menuItems.filter((el) => {
      return el.meal_name.search(e.target.value) != -1;
    });
    this.setState({ selectedBrandMeals: menuItems });
  };
  onSort = (e) => {
    let menuItems = this.state.tempSelectedBrandMeals;
    if (e.target.value == 1) {
      menuItems.sort((a, b) => (a.name > b.name && 1) || -1);
    }
    if (e.target.value == 2) {
      menuItems.sort((a, b) => (a.name < b.name && 1) || -1);
    }
    if (e.target.value == 3) {
      menuItems.sort((a, b) => (a.status < b.status && 1) || -1);
    }
    if (e.target.value == 4) {
      menuItems.sort((a, b) => (a.status > b.status && 1) || -1);
    }

    this.setState({ selectedBrandMeals: menuItems });
  };
}
export default Sentry.withErrorBoundary(withRouter(OperatorMenuScreen), {
  fallback: <ErrorFallbackComponent />,
});
