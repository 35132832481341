import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import { Form, Button, Row, Col } from "react-bootstrap";
import config from "../../services/apiConfig";
import { isEmpty } from "./utils.js";
import { Bar, Pie } from "react-chartjs-2";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import TableData from "../../components/UI/ItemsSoldTable";

class OperatorSalesByEventScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorAccountant:
      getFromLocalStorage("isOperatorAccountant") != "undefined" &&
      getFromLocalStorage("isOperatorAccountant") != "" &&
      getFromLocalStorage("isOperatorAccountant") != null
        ? getFromLocalStorage("isOperatorAccountant")
        : "false",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    totalTransactionFees: 0,
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    total_vouchers_value: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentDidMount() {
    const { isSuperAdminLoggedIn, isOperatorLoggedIn } = this.state;

    this.getVendorsInEvent();
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = (selectedVendorId) => {
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_participated_events",
          vendor_id: selectedVendorId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          console.log(dataResponse);
          this.setState(
            {
              events: dataResponse.data.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getOrders();
              }
              this.getOrders();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_figures",
          email: email,
          password: password,
          vendor: selectedVendorId,
          event: selectedEventId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: dataResponse.card_new_count,
            totalCashOrders: dataResponse.cash_new_count,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            totalTransactionFees: dataResponse.total_transaction_fees,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor,
            salesByHours: dataResponse.sorted_hours_sales,
            total_vouchers_value: dataResponse.total_voucher_value,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        this.getHourlySales();
        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor
        )) {
        }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  //   Get Hourly

  getHourlySales = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isHourlyGraphLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_hourly_sales_old",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            salesByHours: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isHourlyGraphLoading: false });
        this.getMealFrequency();
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });

        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getMealFrequency = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }

    this.setState({ isMealFreqLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_by_vendor_graphs",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      timeout: 120000,
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            meal_freq: dataResponse.meal_freq,
          });
          // var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        // for (let [key, value] of Object.entries(salesByHoursObj)) {
        //   salesByHoursLabelsTemp.push(key);
        // }

        // salesByHoursLabelsTemp.sort();
        // var len = salesByHoursLabelsTemp.length;
        // for (var i = 0; i < len; i++) {
        //   var k = salesByHoursLabelsTemp[i];
        //   salesByHoursDataTemp.push(salesByHoursObj[k]);
        // }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isMealFreqLoading: false });
        // this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        // this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_operator_details_for_stall_ids",
            operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.stalls }, () => {
            console.log(dataResponse.stalls);
            let vendorId = "0";
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };
  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              isSuperAdminLoggedIn: "" + dataResponse.data.is_super_admin,
              isOperatorLoggedIn: "" + dataResponse.data.is_operator,
              vendorName: dataResponse.data.vendor__title,
            },
            () => {
              saveToLocalStorage(
                "isSuperAdminLoggedIn",
                "" + dataResponse.data.is_super_admin
              );
              saveToLocalStorage(
                "isOperatorLoggedIn",
                "" + dataResponse.data.is_operator
              );
              saveToLocalStorage(
                "vendorName",
                "" + dataResponse.data.vendor__title
              );
              saveToLocalStorage("email", email);
              saveToLocalStorage("password", password);
              if (dataResponse.data.is_super_admin === true) {
                this.getVendorsInEvent();
              } else {
                alert("Your not super admin");
                this.getVendorsInEvent();
              }
            }
          );
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };
  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
    });
    window.location.reload();
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      vendorName,
      isOperatorAccountant,
    } = this.state;
    return (
      <>
        <VendorNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isOperatorLoggedIn={isOperatorLoggedIn}
          logout={this.logout}
          vendorName={vendorName}
        ></VendorNavBar>
        {isOperatorAccountant === "true" || isOperatorLoggedIn === "true"
          ? this.renderHome()
          : (window.location.href = "/operator-login")}
      </>
    );
  }

  renderHome() {
    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      selectedDate,
      startDate,
      endDate,
      totalTransactionFees,
      total_vouchers_value,
    } = this.state;
    const Item = styled(Paper)(({ theme }) => ({
      // backgroundColor: "rgba(41, 89, 165, 0.3)",
      // backgroundColor: "#2959a54d",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      display: "flex", // Align items horizontally
      justifyContent: "center", // Center items horizontally
    }));
    let date_range = this.getDateRange(
      selectedEvent.start_date,
      selectedEvent.end_date
    );
    let new_total_orders = totalCashOrders + totalCardOrders;
    const averageOrder =
      Number(total_sales).toFixed(2) / Number(new_total_orders).toFixed(2);

    return (
      <div className="expenses-container" style={{ backgroundColor: "white" }}>
        {!isLoading ? (
          <>
            <React.Fragment>
              <CssBaseline />
              <Container>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={8}>
                    <Item>
                      <div className="icon-container">
                        <LocalShippingIcon
                          sx={{ fontSize: 40, color: "#ef3e6d" }}
                        />
                      </div>
                      <div className="autocomplete-container">
                        <Autocomplete
                          options={vendors}
                          getOptionLabel={(option) => `${option.title}`}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Vendor"
                              variant="standard"
                            />
                          )}
                          onChange={(e, newValue) => {
                            const selectedVendorId = newValue
                              ? newValue.user_ptr_id
                              : "";

                            // Save the selectedVendorId to localStorage
                            localStorage.setItem(
                              "selectedVendorId",
                              selectedVendorId
                            );

                            // Update the state
                            this.setState({ selectedVendorId }, () => {
                              this.getAllEvents(selectedVendorId);
                            });
                          }}
                          value={
                            vendors.find(
                              (vendor) =>
                                vendor.user_ptr_id ===
                                this.state.selectedVendorId
                            ) || null
                          }
                        />
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <div className="icon-container">
                        <CalendarMonthIcon
                          sx={{ fontSize: 40, color: "#ef3e6d" }}
                        />
                      </div>
                      <div className="autocomplete-container">
                        <Autocomplete
                          options={events}
                          getOptionLabel={(option) => `${option.name}`}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Event"
                              variant="standard"
                            />
                          )}
                          onChange={(e, newValue) => {
                            const selectedEventId = newValue ? newValue.id : "";
                            this.setState({ selectedEventId }, () => {
                              this.getOrders(
                                localStorage.getItem("selectedVendorId"),
                                selectedEventId
                              );
                            });
                            saveToLocalStorage(
                              "selectedEventId",
                              selectedEventId
                            );
                          }}
                          value={
                            events.find(
                              (event) => event.id === this.state.selectedEventId
                            ) || null
                          }
                        />
                      </div>
                    </Item>
                  </Grid>

                  {/* Time Selection */}
                  <Grid item xs={12} md={3} lg={3}>
                    <Item>
                      <Form.Label>Select Time</Form.Label>
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          value={this.state.selectedDate}
                          onChange={(e) => {
                            console.log(e.target.value);
                            this.setState(
                              { selectedDate: e.target.value },
                              () => {
                                this.getOrders();
                              }
                            );
                          }}
                        >
                          <>
                            <option value={"-1"}>All Days</option>
                            {date_range.map((e, zrindex) => {
                              return <option value={e}>{e}</option>;
                            })}
                          </>
                        </Form.Control>
                      </Form.Group>
                    </Item>
                  </Grid>
                </Grid>

                {!isEmpty(selectedEvent) ? (
                  <>
                    <Row>
                      <Col item xs={12} md={6} lg={6}>
                        <h5>Event Name:</h5>
                      </Col>
                      <Col item xs={12} md={6} lg={6}>
                        <h5>{selectedEvent.name}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Event Location:</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>{selectedEvent.location}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Event Time:</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>
                          {selectedEvent.start_date +
                            " - " +
                            selectedEvent.end_date +
                            ", " +
                            selectedEvent.start_time +
                            " - " +
                            selectedEvent.end_time}
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Subtotal :</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>
                          $
                          {(
                            (Number(total_sales).toFixed(2) * 90) /
                            100
                          ).toFixed(2)}
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Gst :</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>${(Number(total_sales) * 0.1).toFixed(2)}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Total Sales:</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>
                          ${Number(total_sales).toFixed(2) + "/" + total_orders}
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Average Sale:</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>${Number(averageOrder).toFixed(2)}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h6>Total Cash</h6>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h6>
                          {Number(totalCash).toFixed(2) + "/" + totalCashOrders}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h6>Total Card</h6>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h6>
                          {Number(totalCard).toFixed(2) + "/" + totalCardOrders}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h6>Total Transaction Fees</h6>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h6>{Number(totalTransactionFees).toFixed(2)}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Total Orders</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>{new_total_orders}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Refunds:</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>${Number(canceled_sales).toFixed(2)}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>Refunded Orders</h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>{canceled_orders}</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>
                          <b>Total Sales Less Refund</b>
                        </h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>
                          <b>
                            $
                            {Number(
                              Number(total_sales) - Number(canceled_sales)
                            ).toFixed(2)}
                          </b>
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h6>
                          <b>Total Cash Less Refund:</b>
                        </h6>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h6>
                          <b>
                            $
                            {Number(
                              Number(totalCash) - Number(canceled_sales_cash)
                            ).toFixed(2)}
                          </b>
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h6>
                          <b>Total Card Less Refund:</b>
                        </h6>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h6>
                          <b>
                            $
                            {Number(
                              Number(totalCard) - Number(canceled_sales_card)
                            ).toFixed(2)}
                          </b>
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h6>
                          <b>Total Vouchers Value:</b>
                        </h6>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h6>
                          <b>
                            $<h5>${Number(total_vouchers_value).toFixed(2)}</h5>
                          </b>
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <h5>
                          <b>Total Orders Less Refunded Orders</b>
                        </h5>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <h5>
                          <b>{total_orders - canceled_orders}</b>
                        </h5>
                      </Col>
                    </Row>
                  </>
                ) : null}

                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <Bar
                      data={{
                        labels: this.state.salesByHoursLabels,
                        datasets: [
                          {
                            label: "Sales by hours ($)",
                            data: this.state.salesByHoursData,
                            backgroundColor: this.getBackgroundColor(
                              this.state.salesByHoursLabels.length
                            ),

                            borderWidth: 1,
                          },
                        ],
                      }}
                      height={400}
                      width={600}
                      options={{
                        maintainAspectRatio: false,
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </Col>
                </Row>
                {this.state.newMealFrequency[0] && (
                  <TableData
                    tableData={this.state.newMealFrequency}
                  ></TableData>
                )}
              </Container>
            </React.Fragment>

            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OperatorSalesByEventScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);