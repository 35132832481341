import React from "react";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import EventCardMobile from "./EventCardMobile";
import "./EventSection.css";
const EventSection = ({
  events,

  removeVendor,
  vendors,
  disableDrop,
}) => {
  return (
    <div className="event-cards-container">
      {events.map((item) => (
        <EventCardMobile
          disableDrop={disableDrop}
          key={item.eventDetails?.id}
          style={{ margin: "10px" }}
          event={item}
          removeVendor={removeVendor}
          vendors={vendors}
        />
      ))}
    </div>
  );
};

export default EventSection;
