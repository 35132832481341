import React, { useEffect, useState,useMemo } from "react";
import { deleteFromLocalStorage, getFromLocalStorage } from "../../store";
import AdminNavBar from "../../components/UI/AdminNavBar";
import "./style.css";
import DashboardCard from "../../components/Dashboard/dashboard-card";
import moment from "moment";
import axios from "axios";
import { apiPaths } from "../../services/apiPath";
import apiConfig from "../../services/apiConfig";
import Loader from "../../components/UI/Loader";
import {
  ButtonGroup,
  Container,
  Row,
  Tab,
  Tabs,
  Col,
  ToggleButton,
} from "react-bootstrap";

import { Button, Select } from "antd";
import { Line, Bar, Pie, Radar } from "react-chartjs-2";
import { Autocomplete, styled, TextField } from "@mui/material";
import { Paper } from "material-ui";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Grid } from "@material-ui/core";

const AdminDashboard = () => {
  const [isSuperAdminLoggedIn, setIsSuperAdminLoggedIn] = useState(
    getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
      ? getFromLocalStorage("isSuperAdminLoggedIn")
      : "false"
  );
  const [isAccountantLoggedIn, setIsAccountantLoggedIn] = useState(
    getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
      ? getFromLocalStorage("isAccountantLoggedIn")
      : "false"
  );
  const [isStockAdminLoggedIn, setIsStockAdminLoggedIn] = useState(
    getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
      ? getFromLocalStorage("isStockAdminLoggedIn")
      : "false"
  );
  const [isEventCoordinatorLoggedIn, setIsEventCoordinatorLoggedIn] = useState(
    getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
      ? getFromLocalStorage("isEventCoordinatorLoggedIn")
      : "false"
  );

  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Yearly");
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEventName, setSelectedEventName] = useState("");
  const [chartType, setChartType] = useState("Line");


  const logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  //   const getAllEvents = async () => {
  //     let newEvent = [];
  //     const headers = new Headers();
  //     const url = apiConfig.BASE_URI + apiPaths.adminData;
  //     const emailID = getFromLocalStorage("email");
  //     headers.append("Content-Type", "application/json");

  //     const raw = JSON.stringify({
  //       payload: {
  //         body: {
  //           query_type: "get_all_events_for_stock",
  //           operator_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
  //         },
  //       },
  //     });
  //     const requestOptions = {
  //       method: "POST",
  //       headers: headers,
  //       body: raw,
  //       redirect: "follow",
  //     };
  //     fetch(url, requestOptions)
  //       .then((response) => response.json())
  //       .then((dataResponse) => {
  //         console.log(dataResponse);
  //         setEvents(dataResponse.events ?? []);
  //       });
  //   };

  const getDashboadData = async (eventId) => {
    setLoading(true);
    const organiserData = (getFromLocalStorage("email"));
    // if(organiserData && organiserData[0] && organiserData[0].id)
    const body = {
      query_type: "get_dashboard_data_admin",
      admin_id: organiserData,
      mode: selectedFilter,
      // filter: selectedFilter,
    };

    // body.event_id = selectedFilter === "Event Mode" && eventId ? eventId : "0";
    // body.event_mode = selectedFilter === "Event Mode";

    try {
      const response = await axios.post(
        apiConfig.BASE_URI + apiPaths.adminData,
        {
          payload: {
            body: body,
          },
        }
      );
      if (!response || !response.data || !response.data.success)
        alert("Something went wrong. Refrsh the page to Try again.");
      else setDashboardData(response.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alert("Something went wrong. Refrsh the page to Try again.");
    }
  };
  // useEffect(() => {
  //   getAllEvents();
  // }, []);

  useEffect(() => {
    getDashboadData();
  }, [selectedFilter]);

  const currentYearSales =
    dashboardData &&
    dashboardData.yearly_sales &&
    dashboardData.yearly_sales.current_year_sales
      ? dashboardData.yearly_sales.current_year_sales
      : 0;
  const currentWeekSales =
    dashboardData &&
    dashboardData.weekly_sales &&
    dashboardData.weekly_sales.current_week_sales
      ? dashboardData.weekly_sales.current_week_sales
      : 0;

  const prevYearSales =
    dashboardData &&
    dashboardData.last_year_sales &&
    dashboardData.last_year_sales.last_week_sales
      ? dashboardData.last_year_sales.last_week_sales
      : 0;
  const prevWeekSales =
    dashboardData &&
    dashboardData.last_week_sales &&
    dashboardData.last_week_sales.last_week_sales
      ? dashboardData.last_week_sales.last_week_sales
      : 0;
  //   // console.log(currentYearSales, prevYearSales, yearlyPercentage);
  const yearlyPercentage = !prevYearSales
    ? 100
    : isNaN(
        ((Number(currentYearSales) - Number(prevYearSales)) /
          Number(prevYearSales)) *
          100
      )
    ? 0
    : ((Number(currentYearSales) - Number(prevYearSales)) /
        Number(prevYearSales)) *
      100;
  const weeklyPercentage = !prevWeekSales
    ? 100
    : isNaN(
        ((Number(currentWeekSales) - Number(prevWeekSales)) /
          Number(prevWeekSales)) *
          100
      )
    ? 0
    : ((Number(currentWeekSales) - Number(prevWeekSales)) /
        Number(prevWeekSales)) *
      100;

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const foodItemLables =
    selectedFilter !== "Event Mode"
      ? dashboardData && dashboardData.top_10_items_week
        ? dashboardData.top_10_items_week.map((item) => item.event_meal__name)
        : []
      : dashboardData && dashboardData.top_10_items
      ? dashboardData.top_10_items.map((item) => item.event_meal__name)
      : [];
  const foodItemData =
    selectedFilter !== "Event Mode"
      ? dashboardData && dashboardData.top_10_items_week
        ? dashboardData.top_10_items_week.map((item) => item.total_quantity)
        : []
      : dashboardData && dashboardData.top_10_items
      ? dashboardData.top_10_items.map((item) => item.total_quantity)
      : [];

  const foodItemChartData = {
    labels: foodItemLables,
    datasets: [
      {
        label: "Top 10 Selling Items",
        data: foodItemData,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FFCD94",
          "#C9CBCF",
          "#36C1A5",
          "#FF6684",
        ],
      },
    ],
  };

  const cateringLables =
    selectedFilter !== "Event Mode"
      ? dashboardData && dashboardData.catering_jobs
        ? dashboardData.catering_jobs.map((item) => item.cuisine)
        : []
      : dashboardData && dashboardData.catering_jobs
      ? dashboardData.catering_jobs.map((item) => item.cuisine)
      : [];
  const cateringData =
    selectedFilter !== "Event Mode"
      ? dashboardData && dashboardData.catering_jobs
        ? dashboardData.catering_jobs.map((item) => item.total_count)
        : []
      : dashboardData && dashboardData.catering_jobs
      ? dashboardData.catering_jobs.map((item) => item.total_count)
      : [];

  const cateringChartData = {
    labels: cateringLables,
    datasets: [
      {
        label: "Catering Jobs",
        data: cateringData,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FFCD94",
          "#C9CBCF",
          "#36C1A5",
          "#FF6684",
        ],
      },
    ],
  };

  const topVendorsLables =
    dashboardData && dashboardData.top_10_vendors
      ? dashboardData.top_10_vendors.map((item) => item.vendor_title)
      : [];
  const topVendorsData =
    dashboardData && dashboardData.top_10_vendors
      ? dashboardData.top_10_vendors.map((item) => item.total_sales)
      : [];

  const topVendorsChartData = {
    labels: topVendorsLables,
    datasets: [
      {
        label: "Top Performing Vendors",
        data: topVendorsData,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FFCD94",
          "#C9CBCF",
          "#36C1A5",
          "#FF6684",
        ],
      },
    ],
  };

  const topVendorsChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const monthlySalesLabels = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];
  
  const monthlySalesOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14,
            weight: 'bold',
          },
          color: "#374151"
        }
      },
      tooltip: {
        backgroundColor: "rgba(31, 41, 55, 0.9)",
        titleFont: { size: 16, weight: 'bold' },
        bodyFont: { size: 14 },
        displayColors: false,
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#6B7280",
          font: {
            size: 12,
          }
        }
      },
      y: {
        grid: {
          color: "#E5E7EB",
        },
        ticks: {
          color: "#6B7280",
          font: {
            size: 12,
          }
        }
      },
      "percentage-axis": {
        position: "right",
        ticks: {
          callback: function(value) {
            return value.toFixed(2) + "%";
          },
          color: "#6B7280",
          font: {
            size: 12,
          }
        }
      }
    }
  };

  const orders2025 = dashboardData?.orders_in_2025 ? Object.values(dashboardData.orders_in_2025) : new Array(12).fill(0);
  const orders2024 = dashboardData?.orders_in_2024 ? Object.values(dashboardData.orders_in_2024) : new Array(12).fill(0);
  
  const percentageChange = orders2025.map((sales2025, index) => {
    const sales2024 = orders2024[index] || 1;
    return ((sales2025 - sales2024) / sales2024) * 100;
  });
  
  const monthlySalesData = {
    labels: monthlySalesLabels,
    datasets: [
      {
        label: `Sales ${new Date().getFullYear()}`,
        data: orders2025,
        borderColor: "#4F46E5",
        backgroundColor: "rgba(79, 70, 229, 0.3)",
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 8,
      },
      {
        label: `Sales ${new Date().getFullYear() - 1}`,
        data: orders2024,
        borderColor: "#EF4444",
        backgroundColor: "rgba(239, 68, 68, 0.3)",
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 8,
      },
      {
        label: "% Change from 2024",
        data: percentageChange,
        borderColor: "#10B981",
        backgroundColor: "rgba(16, 185, 129, 0.3)",
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 8,
        yAxisID: "percentage-axis",
      }
    ],
  };
  
  const chartComponents = {
    Line: <Line data={monthlySalesData} options={monthlySalesOptions} width={500} height={150} />, 
    Bar: <Bar data={monthlySalesData} options={monthlySalesOptions} width={500} height={150} />, 
    Pie: <Pie data={monthlySalesData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }}  />,
    Radar: <Radar data={monthlySalesData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
  };
  

  
  

  const topPayoutsData = {
    labels: dashboardData?.top_10_payouts?.map(item => item.vendor__title) || [],
    datasets: [
      {
        label: "Top 10 Payouts",
        data: dashboardData?.top_10_payouts?.map(item => item.total_payout) || [],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#FFCD94", "#C9CBCF", "#36C1A5"],
      }
    ]
  };

  const cateringEnquiriesData = {
    labels: dashboardData?.catering_enquires?.map(item => item.cuisine) || [],
    datasets: [
      {
        label: "Catering Enquiries",
        data: dashboardData?.catering_enquires?.map(item => item.total_quantity) || [],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#FFCD94", "#C9CBCF", "#36C1A5"],
      }
    ]
  };
  
 
  
  return (
    <div>
      <AdminNavBar
        isSuperAdminLoggedIn={isSuperAdminLoggedIn}
        isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
        isAccountantLoggedIn={isAccountantLoggedIn}
        isStockAdminLoggedIn={isStockAdminLoggedIn}
        logout={logout}
      ></AdminNavBar>
      {!isSuperAdminLoggedIn ||
      !isEventCoordinatorLoggedIn ||
      !isAccountantLoggedIn ||
      !isStockAdminLoggedIn ? (
        (window.location.href = "/admin")
      ) : (
        <div className="dashboard-wrapper">
          <h1 className="vendor-header">Admin Dashboard</h1>
          <ButtonGroup className="mb-3 custom-button-group">
            {["Yearly", "Weekly"].map((filter) => (
              <ToggleButton
                key={filter}
                type="radio"
                variant="outline-primary"
                value={filter}
                checked={selectedFilter === filter}
                onChange={() => handleFilterChange(filter)}
              >
                {filter}
              </ToggleButton>
            ))}
          </ButtonGroup>
          {selectedFilter === "Event Mode" ? (
            <div
              style={{
                padding: 16,
                display: "flex",
                gap: 4,
                flexDirection: "column",
              }}
            >
              <div className="autocomplete-container">
                <Autocomplete
                  options={events}
                  getOptionLabel={(option) => `${option.name}`}
                  // sx={{ width: this.state.width }}
                  renderInput={(params) => (
                    <>
                      <p className="text-white" style={{ color: "white" }}>
                        Select Event:
                      </p>
                      <TextField {...params} variant="standard" />
                    </>
                  )}
                  onChange={(e, newValue) => {
                    const id = newValue ? newValue.id : "";
                    const eventName = newValue ? newValue.name : "";

                    setSelectedEventName(eventName);
                    setSelectedEventId(id);
                    //   getDashboadData(id);
                  }}
                  value={
                    events.find((event) => event.id === selectedEventId) || null
                  }
                />
              </div>
            </div>
          ) : null}

          <Grid container spacing={12} className="relative">
            <Loader loading={loading} />
            <Grid item xs={12}>
              <DashboardCard
                className="lg-chart"
                // style={{ maxHeight: 500 }}
                show={selectedFilter === "Yearly"}
                title={`Monthly Sales Comparison (${new Date().getFullYear()} vs ${new Date().getFullYear() -
                  1})`}
              >
                 <div>
                 {/* <select onChange={(e) => setChartType(e.target.value)} value={chartType}>
        <option value="Line">Line Chart</option>
        <option value="Bar">Bar Chart</option>
        <option value="Pie">Pie Chart</option>
        <option value="Radar">Radar Chart</option>
      </select>
      {chartComponents[chartType]} */}
      <Line data={monthlySalesData} options={monthlySalesOptions} width={500} height={150} />
    </div>

              </DashboardCard>
             
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container scpacing={12}>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    link={"/admin-external-operators"}
                    show={selectedFilter === "Yearly"}
                    title={
                      "Total Sales for the Year " + new Date().getFullYear()
                    }
                  >
                    <p className="text-green">
                      Sales:{" "}
                      {dashboardData &&
                      dashboardData.yearly_sales &&
                      dashboardData.yearly_sales.current_year_sales
                        ? "$ " + dashboardData.yearly_sales.current_year_sales
                        : "NA"}
                    </p>
                    <p className="text-green">
                      Orders:{" "}
                      {dashboardData &&
                      dashboardData.yearly_sales &&
                      dashboardData.yearly_sales.current_year_order_count
                        ? dashboardData.yearly_sales.current_year_order_count
                        : 0}
                    </p>
                    <div className="flex items-center" style={{ gap: 6 }}>
                      <div
                        className={yearlyPercentage < 0 ? "down" : "up"}
                      ></div>
                      <p
                        className={
                          yearlyPercentage < 0 ? "text-red" : "text-green"
                        }
                      >
                        {yearlyPercentage < 0 ? "Down" : "Up"} by{" "}
                        {Math.abs(yearlyPercentage).toFixed(2) + "%"} in
                        comparison to last year
                      </p>
                    </div>
                  </DashboardCard>
                  <DashboardCard
                    style={{ height: "100%" }}
                    show={selectedFilter === "Weekly"}
                    title={`Top Weekly Sales (Week ${moment().week()} of ${new Date().getFullYear()})`}
                    link="/admin-external-operators"
                  >
                    <p className="text-green">
                      Sales:{" "}
                      {dashboardData &&
                      dashboardData.weekly_sales &&
                      dashboardData.weekly_sales.current_week_sales
                        ? "$ " + dashboardData.weekly_sales.current_week_sales
                        : "NA"}
                    </p>
                    <p className="text-green">
                      Orders:{" "}
                      {dashboardData &&
                      dashboardData.weekly_sales &&
                      dashboardData.weekly_sales.current_week_order_count
                        ? dashboardData.weekly_sales.current_week_order_count
                        : 0}
                    </p>
                    <div className="flex items-center" style={{ gap: 6 }}>
                      <div
                        className={weeklyPercentage < 0 ? "down" : "up"}
                      ></div>
                      <p
                        className={
                          weeklyPercentage < 0 ? "text-red" : "text-green"
                        }
                      >
                        {weeklyPercentage < 0 ? "Down" : "Up"} by{" "}
                        {Math.abs(weeklyPercentage).toFixed(2) + "%"} in
                        comparison to last week
                      </p>
                    </div>
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    link={"/admin-payout"}
                    title={`Total Payout`}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p
                        className="text-green"
                        style={{
                          fontSize: 56,
                          color: "#285915",
                          fontWeight: "bold",
                        }}
                      >
                        {/* {dashboardData.qs_total_payout.total_payout ?? 0} */}
                      </p>

                      <Bar data={topPayoutsData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />

                    </div>
                  </DashboardCard>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    link={"/admin-catering-applications"}
                    // style={{ maxHeight: 300 }}
                    title={`Total Inquiries (Week ${moment().week()} of ${new Date().getFullYear()})`}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p
                        className="text-green"
                        style={{
                          fontSize: 56,
                          color: "#285915",
                          fontWeight: "bold",
                        }}
                      >
                        ...
                      </p>
                    </div>
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    link={"/admin-pending-subscription"}
                    // style={{ maxHeight: 300 }}
                    title={`Total Subscriptions`}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p
                        className="text-green"
                        style={{
                          fontSize: 56,
                          color: "#285915",
                          fontWeight: "bold",
                        }}
                      >
                        {dashboardData && dashboardData.total_subscriptions
                          ? dashboardData.total_subscriptions
                          : "0"}
                      </p>
                    </div>
                  </DashboardCard>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    // link={"/admin-pending-subscription"}
                    // style={{ maxHeight: 300 }}
                    title={`Total Stock Ordered`}
                  >
                    <p className="text-green">
                      Total Stock Ordered:{" "}
                      {dashboardData &&
                      dashboardData.total_stock_ordered &&
                      dashboardData.total_stock_ordered.total_stock_ordered
                        ? dashboardData.total_stock_ordered.total_stock_ordered
                        : 0}
                    </p>
                    <p className="text-green">
                      Ordered This Week:{" "}
                      {dashboardData &&
                      dashboardData.total_stock_ordered &&
                      dashboardData.total_stock_ordered.current_week_order_count
                        ? dashboardData.total_stock_ordered
                            .current_week_order_count
                        : 0}
                    </p>
                    {/* <p className="text-green">
                  Week Count is{" "}
                  {dashboardData &&
                  dashboardData.total_stock_ordered &&
                  dashboardData.total_stock_ordered.total_stock_ordered
                    ? (
                        (dashboardData.total_stock_ordered
                          .current_week_order_count /
                          dashboardData.total_stock_ordered
                            .total_stock_ordered) *
                        100
                      ).toFixed(2)
                    : 0}
                  % of Total Stock Ordered
                </p> */}
                  </DashboardCard>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    // style={{ maxHeight: 300 }}
                    title={`Requested Stock Quotes`}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <li
                        //     key={i}
                        className="card-list-item"
                        style={{ justifyContent: "space-between" }}
                      >
                        ...
                        {/* <div className="flex" style={{ gap: 6 }}>
                    <p className="serial">#{1}</p>
                    <p className="item-title">POS</p>
                  </div>
                  <p className="total-sold">75</p> */}
                      </li>
                    </div>
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    title={`Revenue from Services`}
                    style={{ height: "100%" }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {/* <p
                        className="text-green"
                        style={{
                          fontSize: 56,
                          color: "#285915",
                          fontWeight: "bold",
                        }}
                      >
                        20
                      </p> */}
                    </div>
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    title={`Membership Dues`}
                  >
                    {/* <DashboardSubCard title="Vendors Required to Upload Documentation">
                     <ul>
                       {dashboardData && dashboardData.followup_tasks
                         ? dashboardData.followup_tasks.map((vendor) => (
                             <li
                               key={vendor.vendor_id}
                               className="card-list-item"
                               style={{
                                 alignItems: "center",
                                 justifyContent: "space-between",
                               }}
                             >
                               <p
                                 className="vendor-title"
                                 style={{ maxWidth: "50%" }}
                               >
                                 {vendor.vendor_title}
                               </p>
                             </li>
                           ))
                         : "NA"}
                     </ul>
                   </DashboardSubCard>
                   <DashboardSubCard title="Contracts to Sign">
                     <ul>
                       <li
                         className="card-list-item"
                         style={{ alignItems: "center" }}
                       >
                         <img
                           src="https://wtt-aws-bucket.s3.amazonaws.com/trucks_avatars/modified_logo_0_831"
                           style={{
                             borderRadius: "10px",
                             border: "0.5px solid #ccc",
                             width: "36px",
                             height: "36px",
                             objectFit: "cover",
                           }}
                         />
                         <p className="vendor-title">Chatime Trailer</p>
                       </li>
                     </ul>
                   </DashboardSubCard>
                   <DashboardSubCard title="SOTs to Review">
                     <ul>
                       {dashboardData && dashboardData.sot_to_review
                         ? dashboardData.sot_to_review.map((sot, i) => (
                             <li
                               key={i}
                               className="card-list-item"
                               style={{
                                 alignItems: "center",
                                 justifyContent: "space-between",
                               }}
                             >
                               <p
                                 className="vendor-title"
                                 style={{ maxWidth: "50%" }}
                               >
                                 {sot.event__name}
                               </p>
                               <p
                                 className="total-sold"
                                 style={{ textAlign: "left" }}
                               >
                                 {sot.vendor__title}
                               </p>
                             </li>
                           ))
                         : "NA"}
                     </ul>
                   </DashboardSubCard> */}
                  </DashboardCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={12}>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    // style={{ maxHeight: 300 }}
                    background={"dark"}
                    title={`Current Active Users`}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p
                        className="text-green"
                        style={{
                          fontSize: 56,
                          color: "#285915",
                          fontWeight: "bold",
                        }}
                      >
                        ...
                      </p>
                    </div>
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    // style={{ maxHeight: 300 }}
                    background={"dark"}
                    title={`Current Hardware Levels`}
                  >
                    <li
                      //     key={i}
                      className="card-list-item"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="flex" style={{ gap: 6 }}>
                        <p className="serial">#{1}</p>
                        <p className="item-title">POS</p>
                      </div>
                      <p className="total-sold">75</p>
                    </li>
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    background="dark"
                    title={
                      selectedFilter === "Weekly"
                        ? `Upcoming Events\n(Week ${moment().week()} of ${new Date().getFullYear()})`
                        : `Upcoming Events (Year ${new Date().getFullYear()})`
                    }
                  >
                    {/* TODO: Map Events */}
                    <ul>
                      {dashboardData && dashboardData.upcoming_events
                        ? dashboardData.upcoming_events
                            .slice(0, 15)
                            .map((event) => (
                              <li key={event.id}>
                                <p className="text-green">{event.name}</p>
                              </li>
                            ))
                        : null}
                    </ul>
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    background="dark"
                    title={`Active EOIs`}
                  >
                    <ul>
                      {/* TODO: Map EOIs */}
                      {dashboardData && dashboardData.active_eoi
                        ? dashboardData.active_eoi
                            .slice(0, 15)
                            .map((eoi, i) => (
                              <li key={i}>
                                <p className="text-green">
                                  {eoi.eoi_event_name}
                                </p>
                              </li>
                            ))
                        : null}
                    </ul>
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    background="dark"
                    title={
                      selectedFilter === "Weekly"
                        ? `Top 10 Performing Vendors (Week ${moment().week()} of ${new Date().getFullYear()})`
                        : `Top 10 Performing Vendors (Year ${new Date().getFullYear()})`
                    }
                  >
                    <ul>
                      {dashboardData && dashboardData.top_10_vendors
                        ? dashboardData.top_10_vendors.map((item, i) => (
                            <li
                              key={i}
                              className="card-list-item"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="flex" style={{ gap: 6 }}>
                                <p className="serial">#{i + 1}</p>
                                <p className="item-title">
                                  {item.vendor_title}
                                </p>
                              </div>
                              <p className="total-sold">
                                Total Sales: $ {item.total_sales}
                              </p>
                            </li>
                          ))
                        : null}
                    </ul>
                    <Bar
                      options={topVendorsChartOptions}
                      data={topVendorsChartData}
                    />
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    background="dark"
                    title={
                      selectedFilter === "Weekly"
                        ? `Top 10 Food Items (Week ${moment().week()} of ${new Date().getFullYear()})`
                        : `Top 10 Food Items (Year ${new Date().getFullYear()})`
                    }
                  >
                    <ul>
                      {dashboardData && dashboardData.top_10_items_week
                        ? dashboardData.top_10_items_week.map((item, i) => (
                            <li
                              key={i}
                              className="card-list-item"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="flex" style={{ gap: 6 }}>
                                <p className="serial">#{i + 1}</p>
                                <p className="item-title">
                                  {item.event_meal__name}
                                </p>
                              </div>
                              <p className="total-sold">
                                Total Sold: {item.total_quantity}
                              </p>
                            </li>
                          ))
                        : null}
                    </ul>
                    <Pie data={foodItemChartData} />
                  </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardCard
                    style={{ height: "100%" }}
                    link="/admin-catering-applications"
                    //   show={selectedFilter === "Yearly"}
                    background={"dark"}
                    title={`Catering`}
                  >
                    <ul>
                      {dashboardData && dashboardData.catering_enquires
                        ? dashboardData.catering_enquires.map((item, i) => (
                            <li
                              key={i}
                              className="card-list-item"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="flex" style={{ gap: 6 }}>
                                <p className="serial">#{i + 1}</p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 3,
                                  }}
                                >
                                  <b style={{ color: "#ef3e6d" }}>Cuisine</b>
                                  <p className="item-title">{item.cuisine}</p>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 3,
                                }}
                              >
                                <b
                                  style={{ color: "#ef3e6d" }}
                                  className="total-sold"
                                >
                                  Total Count
                                </b>
                                <p className="total-sold">
                                  Total Count: {item.total_quantity}
                                </p>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                    <Pie data={cateringEnquiriesData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                  </DashboardCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

const DashboardSubCard = ({ title, children }) => {
  return (
    <div style={{ marginBottom: 8 }}>
      <h4 className="sub-card-heading">{title}</h4>
      {children}
    </div>
  );
};

export default AdminDashboard;