import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import SearchBarVendor from "../../components/SearchBarVendor";
import SearchResultsListVendor from "../../components/SearchResultsListVendor";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from "chart.js";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import * as Sentry from "@sentry/react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { Card } from "react-bootstrap";

import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./style.css";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";

// Register the necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

moment.locale("en-au");

class OperatorApplicationScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") !== "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isOperatorEventCoordinator:
      getFromLocalStorage("isOperatorEventCoordinator") !== "undefined" &&
      getFromLocalStorage("isOperatorEventCoordinator") !== "" &&
      getFromLocalStorage("isOperatorEventCoordinator") !== null
        ? getFromLocalStorage("isOperatorEventCoordinator")
        : "false",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") !== "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") !== "" &&
      getFromLocalStorage("isOperatorLoggedIn") !== null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    isOperatorOperations:
      getFromLocalStorage("isOperatorOperations") !== "undefined" &&
      getFromLocalStorage("isOperatorOperations") !== "" &&
      getFromLocalStorage("isOperatorOperations") !== null
        ? getFromLocalStorage("isOperatorOperations")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    bookingRequests: [],
    vendors: [],
    filteredVendors: [],
    startDate:
      getFromLocalStorage("startDate") !== "undefined" &&
      getFromLocalStorage("startDate") !== "" &&
      getFromLocalStorage("startDate") !== null &&
      getFromLocalStorage("startDate").toString() !== "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") !== "undefined" &&
      getFromLocalStorage("endDate") !== "" &&
      getFromLocalStorage("endDate") !== null &&
      getFromLocalStorage("endDate").toString() !== "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    isLoading: false,
    formattedEvents: [],
    confirmedJobsEvents: [], // Initialize confirmedJobsEvents
    showOperatorModal: false,
    showVendorModal: false,
    showConfirmedJobs: false, // State to toggle between views
    isEditEventModalOpen:false,
    selectedEvent:''
  };

  handleEventClick = (event) => {
    console.log("Event clicked:", event);
    this.setState({
      selectedEvent: { ...event },
      isEditEventModalOpen: true,
    });
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isOperatorOperations,
      isOperatorLoggedIn,
      isOperatorEventCoordinator,
    } = this.state;

    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true" ||
      isOperatorOperations == "true" ||
      isOperatorLoggedIn == "true" ||
      isOperatorEventCoordinator == "true"
    ) {
      this.getVendorsInEvent();
      this.getDashboardData(this.state.selectedVendorId);
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  getVendorsInEvent = () => {
    const { isSuperAdminLoggedIn } = this.state;
    this.setState({ isLoading: false });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const operator = JSON.parse(getFromLocalStorage("Operator"))[0];
    const url = config.BASE_URI + apiPaths.adminData;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        payload: {
          body: {
            query_type: "get_operator_details_for_stall_ids",
            operator_id: operator.id,
          },
        },
      }),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.stalls }, () => {
          let vendorId = "0";
          this.setState({ selectedVendorId: vendorId }, () => {
            saveToLocalStorage("selectedVendorId", vendorId);
          });
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getDashboardData = (selectedVendorId) => {
    const { startDate, endDate } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_share_applications_calender_operator",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          start_date: moment(startDate).format("DD MMM YYYY"),
          end_date: moment(endDate).format("DD MMM YYYY"),
          vendor_id: selectedVendorId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        this.setState({ bookingRequests: dataResponse.qs_sharable_stalls });
        if (dataResponse.success) {
          // Process qs_sharable_stalls to get applications data
          const applications = dataResponse.qs_sharable_stalls.map((item) => {
            const eventDate = item.timestamp
              ? new Date(moment(item.timestamp, "DD MMM YYYY hh:mm A").toDate())
              : null;
          
            return {
              id: item.id,
              title: `${item.event_name} - ${item.vendor__title}`,
              start: eventDate,
              end: eventDate,
              allDay: true,
              status: item.status,
             
              tooltip: `Event Name: ${item.event_name}\nVendor: ${
                item.vendor__title
              }\nOperator: ${item.operator__first_name} ${
                item.operator__last_name
              }\nEmail: ${item.email}\nStatus: ${
                item.status
              }\nAmount of Times Page Opened: ${
                item.amount_of_times_page_opened
              }\nDoes Organiser Want to Book the Truck: ${
                item.does_organiser_want_to_book_the_truck ? "Yes" : "No"
              }\nDid Organiser Open Documents: ${
                item.did_organiser_open_documents ? "Yes" : "No"
              }`,
            };
          });

          // Process vendor_calendars to get confirmed jobs data
          const confirmedJobs = dataResponse.vendor_calendars.map((item) => {
            debugger;
            const eventDate = new Date(item.event_date);
            return {
              id: item.id,
              title: `${item.vendor__title} - ${item.event_name}`,
              start: eventDate,
              end: eventDate,
              beverage_notes: item.beverage_notes,
              voucher_notes: item.voucher_notes,
              allDay: true,
              status: item.runsheet_status,
              tooltip: `Event Name: ${item.event_name}\nLocation: ${item.location}\nStart Time: ${item.start_time}\nEnd Time: ${item.end_time}`,
            };
          });

          // Store both applications and confirmed jobs in the state
          this.setState({
            formattedEvents: applications,
            confirmedJobsEvents: confirmedJobs,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  toggleConfirmedJobs = () => {
    this.setState((prevState) => ({
      showConfirmedJobs: !prevState.showConfirmedJobs,
    }));
  };

  renderHome() {
    const {
      bookingRequests,
      filteredVendors,
      isLoading,
      vendors,
      selectedEvent,
      isEditEventModalOpen,
      startDate,
      endDate,
      showOperatorModal,
      showVendorModal,
      showConfirmedJobs,
      formattedEvents,
      confirmedJobsEvents,
    } = this.state;

    // Prepare Data for the Operator Chart
    const operatorData = bookingRequests.reduce((acc, item) => {
      const operatorFullName = `${item.operator__first_name} ${item.operator__last_name}`;
      acc[operatorFullName] = (acc[operatorFullName] || 0) + 1;
      return acc;
    }, {});

    debugger;

    const chartData = {
      labels: Object.keys(operatorData),
      datasets: [
        {
          label: "Number of Applications",
          data: Object.values(operatorData),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Applications by Operator",
        },
      },
    };

    // Prepare Data for the Vendor Chart
    const vendorData = bookingRequests.reduce((acc, item) => {
      const vendorTitle = item.vendor__title;
      acc[vendorTitle] = (acc[vendorTitle] || 0) + 1;
      return acc;
    }, {});

    const vendorChartData = {
      labels: Object.keys(vendorData),
      datasets: [
        {
          label: "Number of Applications",
          data: Object.values(vendorData),
          backgroundColor: "rgba(153, 102, 255, 0.2)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
        },
      ],
    };

    const setFilteredVendors = (data) => {
      this.setState({ filteredVendors: data });
    };

    const selectedVendorP = (data) => {
      this.setState({ selectedVendorSearch: data });
    };

    const selectedVendorQ = (data) => {
      saveToLocalStorage("selectedVendorId", "" + data);
      this.setState({ selectedVendorId: "" + data }, () => {});
    };

    const eventStyleGetter = (event) => {
      let backgroundColor = "#FFAB91";
      let textColor = "white"; // Default text color

      switch (event.status) {
        case "Pending":
          backgroundColor = "yellow";
          textColor = "black";
          break;
        case "Rejected":
          backgroundColor = "red";
          break;
        case "Accepted":
          backgroundColor = "green";
        default:
          backgroundColor = "blue";
          break;
      }

      return {
        style: {
          backgroundColor,
          color: textColor,
        },
      };
    };

    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Vendor</Form.Label>
                  <div className="App">
                    <div className="search-bar-container">
                      <SearchBarVendor
                        vendorList={vendors}
                        placeHolder={"Search Vendors"}
                        setFilteredVendors={setFilteredVendors}
                        selectedVendorId={this.state.selectedVendorId}
                        selectedVendorSearch={this.state.selectedVendorSearch}
                      />
                      <SearchResultsListVendor
                        filteredVendors={filteredVendors}
                        selectedVendorP={selectedVendorP}
                        selectedVendorQ={selectedVendorQ}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <b>Requested Events in Date Range:</b>
                  <Row style={{ marginTop: 10 }}>
                    <Col xs={12} md={4}>
                      <p>Start Date</p>
                      <DatePicker
                        className="date-picker"
                        dateFormat={"dd/MM/yyyy"}
                        selected={startDate}
                        onChange={(date) => this.setStartDate(date)}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <p>End Date</p>
                      <DatePicker
                        className="date-picker"
                        dateFormat={"dd/MM/yyyy"}
                        selected={endDate}
                        onChange={(date) => this.setEndDate(date)}
                      />
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      <p>&nbsp;</p>
                      <Button
                        onClick={() => {
                          this.setState({ resetFilter: "false" }, () => {
                            saveToLocalStorage(
                              "resetFilter",
                              this.state.resetFilter.toString()
                            );
                            this.getDashboardData(this.state.selectedVendorId);
                          });
                        }}
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Form.Check
                type="switch"
                id="view-switch"
                label="Show Confirmed Jobs"
                checked={this.state.showConfirmedJobs}
                onChange={this.toggleConfirmedJobs}
              />

              <Row className="chart-container">
                <Col md={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Total Applications</Card.Title>
                      <Card.Text>{bookingRequests.length}</Card.Text>
                      <Button
                        variant="primary"
                        onClick={() =>
                          this.setState({ showOperatorModal: true })
                        }
                      >
                        See Details
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Applications by Vendor</Card.Title>
                      <Card.Text>{Object.keys(vendorData).length}</Card.Text>
                      <Button
                        variant="primary"
                        onClick={() => this.setState({ showVendorModal: true })}
                      >
                        See Details
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <h3>{showConfirmedJobs ? "Confirmed Jobs" : "Applications"}</h3>
              <Calendar
  localizer={momentLocalizer(moment)}
  events={showConfirmedJobs ? confirmedJobsEvents : formattedEvents}
  startAccessor="start"
  onSelectEvent={this.handleEventClick}
  endAccessor="end"
  titleAccessor="title"
  eventPropGetter={eventStyleGetter}
  style={{ height: 500 }}
  components={{
    event: ({ event }) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${event.id}`}>
            <div style={{ whiteSpace: "pre-wrap" }}>{event.tooltip}</div>
          </Tooltip>
        }
      >
        <div>{event.title}</div>
      </OverlayTrigger>
    ),
  }}
/>            </Container>

{isEditEventModalOpen && selectedEvent && (
  <Modal
    show={isEditEventModalOpen}
    onHide={() => this.setState({ isEditEventModalOpen: false })}
  >
    <Modal.Header closeButton>
      <Modal.Title>Edit Event</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group controlId="eventTitle">
          <Form.Label>Event Title</Form.Label>
          <Form.Control
            type="text"
            value={selectedEvent.title}
            onChange={(e) =>
              this.setState({
                selectedEvent: {
                  ...selectedEvent,
                  title: e.target.value,
                },
              })
            }
          />
        </Form.Group>
        <Form.Label>Statement of Trade Status</Form.Label>
<Form.Group>
  <Form.Check
    type="radio"
    label="Pending"
    name="tradeStatus"
    value="pending"
    checked={selectedEvent.tradeStatus === "pending"}
    onChange={(e) =>
      this.setState({
        selectedEvent: {
          ...selectedEvent,
          tradeStatus: e.target.value,
        },
      })
    }
  />
  <Form.Check
    type="radio"
    label="Submitted"
    name="tradeStatus"
    value="submitted"
    checked={selectedEvent.tradeStatus === "submitted"}
    onChange={(e) =>
      this.setState({
        selectedEvent: {
          ...selectedEvent,
          tradeStatus: e.target.value,
        },
      })
    }
  />
  <Form.Check
    type="radio"
    label="Approved"
    name="tradeStatus"
    value="approved"
    checked={selectedEvent.tradeStatus === "approved"}
    onChange={(e) =>
      this.setState({
        selectedEvent: {
          ...selectedEvent,
          tradeStatus: e.target.value,
        },
      })
    }
  />
</Form.Group>

        <Form.Group controlId="startDateTime">
          <Form.Label>Start Date & Time</Form.Label>
          <DatePicker
            selected={new Date(selectedEvent.start)}
            onChange={(date) =>
              this.handleDateChange("start", date)
            }
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="Pp"
          />
        </Form.Group>
        <Form.Group controlId="endDateTime">
          <Form.Label>End Date & Time</Form.Label>
          <DatePicker
            selected={new Date(selectedEvent.end)}
            onChange={(date) =>
              this.handleDateChange("end", date)
            }
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="Pp"
          />
        </Form.Group>
        <Form.Group controlId="eventDetails">
          <Form.Label>Beverage Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={selectedEvent.beverage_notes}
            onChange={(e) =>
              this.setState({
                selectedEvent: {
                  ...selectedEvent,
                  desc: e.target.value,
                },
              })
            }
          />
        </Form.Group>
        <Form.Group controlId="eventDetails">
          <Form.Label>Voucher Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={selectedEvent.vouchers_notes}
            onChange={(e) =>
              this.setState({
                selectedEvent: {
                  ...selectedEvent,
                  desc: e.target.value,
                },
              })
            }
          />
        </Form.Group>
        <Form.Group controlId="eventDetails">
          <Form.Label>Bump In/ Out Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={selectedEvent.bump_in_out_notes}
            onChange={(e) =>
              this.setState({
                selectedEvent: {
                  ...selectedEvent,
                  desc: e.target.value,
                },
              })
            }
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        onClick={() =>
          this.setState({ isEditEventModalOpen: false, selectedEvent: null })
        }
      >
        Cancel
      </Button>
      <Button variant="primary" onClick={this.saveEventChanges}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
)}

            <Modal
              show={showOperatorModal}
              onHide={() => this.setState({ showOperatorModal: false })}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Applications by Operator</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Bar data={chartData} options={chartOptions} />
              </Modal.Body>
            </Modal>

            <Modal
              show={showVendorModal}
              onHide={() => this.setState({ showVendorModal: false })}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Applications by Vendor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Bar
                  data={vendorChartData}
                  options={{
                    ...chartOptions,
                    title: { text: "Applications by Vendor" },
                  }}
                />
              </Modal.Body>
            </Modal>

            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img
              src={require("../../assets/img/loading.gif")}
              alt="Loading..."
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isOperatorOperations,
      isOperatorLoggedIn,
      isOperatorEventCoordinator,
    } = this.state;

    // Check if any of the admin roles are logged in

    const isLoggedIn =
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true" ||
      isOperatorOperations == "true" ||
      isOperatorLoggedIn == "true" ||
      isOperatorEventCoordinator == "true";

    if (!isLoggedIn) {
      window.location.href = "/admin";
      return null;
    }

    return (
      <div>
        <VendorNavBar isOperatorLoggedIn={true}></VendorNavBar>

        {this.renderHome()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OperatorApplicationScreen, {})
);
