import React from "react";
import "./style1.css";
import "./Component/style1.css";
import Footer from "../../components/UI/Footer";
import { useRef, useState, useEffect, useCallback, memo } from "react";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { Button } from "react-bootstrap";
import { getFromLocalStorage } from "../../store";
const OperatorLandingScreen = memo(() => {
  const [active, setActive] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setActive(!active);
    setIsActive(!isActive);
  };
  const [showForm, setShowForm] = useState(false);
  const [input, setInput] = useState("");
  const inputHandler = (e) => {
    setInput(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (input) {
      console.log(input);
      let data = {
        method: "POST",
        body: JSON.stringify({
          key: "KqogytxmJIhoq_Cd3Wxqmg",
          template_name: "vendor-intro",
          template_content: [
            {
              name: "YOUR_MERGETAG",
              content: "<p>Testing</p>",
            },
          ],
          message: {
            subject: "Welcome to Where The Truck",
            from_email: "noreply@wherethetruck.at",
            from_name: "Where The Truck",
            to: [
              {
                email: input ? input : "aaradhanah@amfvg.com.au",
                type: "to",
              },
            ],
            headers: {
              "Reply-To": "noreply@wherethetruck.at",
            },
            important: false,
            track_opens: null,
            track_clicks: null,
            auto_text: null,
            auto_html: null,
            inline_css: null,
            url_strip_qs: null,
            preserve_recipients: null,
            view_content_link: null,
            bcc_address: null,
            tracking_domain: null,
            signing_domain: null,
            return_path_domain: null,
            merge: true,
            merge_language: "mailchimp",
            global_merge_vars: [
              {
                name: "YOUR_MERGETAG",
                content: "Hello, this is the content of the email!",
              },
            ],
          },
          async: false,
          ip_pool: "Main Pool",
        }),
      };
      return fetch(
        "https://mandrillapp.com/api/1.0/messages/send-template.json",
        data
      )
        .then((response) => handleFormClose())
        .then((emailResponse) => {
          handleFormClose();
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error("Email Response Error ", error);
          handleFormClose();
        });
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 1000); // Show modal after 10 seconds

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="whole">
        <div className="whole-html">
          <section id="header">
            <div className="header containerl">
              <div className="nav-bar">
                <div className="brand">
                  <a href="#hero">
                    <h1 style={{ fontSize: "2em", marginBttom: 0 }}>
                      <span>Home</span>
                    </h1>
                  </a>
                </div>
                <div className="nav-list">
                  <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                  </div>
                  <ul className={active ? "active" : ""}>
                    <li>
                      <a
                        href="#services"
                        data-after="Service"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        Services
                      </a>
                    </li>
                    <li>
                      <a
                        href="#solutions"
                        data-after="Solution"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        Solutions
                      </a>
                    </li>
                    <li>
                      <a
                        href="#projects"
                        data-after="Projects"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        Subscription
                      </a>
                    </li>
                    <li>
                      <a
                        href="#guide"
                        data-after="Guide"
                        style={{ fontSize: "1.5em" }}
                        onClick={toggleMenu}
                      >
                        POS Price Guide
                      </a>
                    </li>
                    {getFromLocalStorage("isOperatorLoggedIn") === "true" ||
                    getFromLocalStorage("isTruckManager") === "true" ||
                    getFromLocalStorage("isOperatorOperations") === "true" ||
                    getFromLocalStorage("isOperatorAccountant") === "true" ||
                    getFromLocalStorage("isOperatorEventCoordinator") ===
                      "true" ||
                    getFromLocalStorage("isOperatorStockAdmin") === "true" ? (
                      <li>
                        <a
                          href="/operator-dashboard"
                          data-after="Dashboard"
                          style={{ fontSize: "1.5em" }}
                          onClick={toggleMenu}
                          className="dashboard-link-btn"
                        >
                          Go to Dashboard
                        </a>
                      </li>
                    ) : (
                      <>
                        <li>
                          <a
                            href="/operator-login"
                            data-after="About"
                            style={{ fontSize: "1.5em" }}
                            onClick={toggleMenu}
                          >
                            Login
                          </a>
                        </li>
                        <li>
                          <a
                            href="/register"
                            data-after="Contact"
                            style={{ fontSize: "1.5em" }}
                            onClick={toggleMenu}
                          >
                            Register
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/* <Modal /> */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showForm}
            onClose={handleFormClose}
            className="modalnvm"
          >
            <Box className="modalnvm-content">
              <Container>
                <Form onSubmit={submitHandler}>
                  <CloseIcon
                    fontSize="large"
                    style={{ float: "right" }}
                    onClick={handleFormClose}
                  />
                  <H2>Subscribe to our newsletter</H2>
                  <p>
                    Stay in the loop with what's trucking! Get new offers and
                    discounts from WTT in your inbox.
                  </p>
                  <Input type="email" onChange={inputHandler} />
                  <Button style={{ margin: 10 }} onClick={submitHandler}>
                    Submit
                  </Button>
                </Form>
              </Container>
            </Box>
          </Modal>
          <section id="hero">
            <div class="hero containerl">
              <div></div>
            </div>
          </section>
          <section id="services">
            <div class="services containerl">
              <div class="service-top">
                <h1 class="section-title">
                  <span>Services</span>
                </h1>
                <p className="pl">
                  As an Australian Mobile Food Vendors Group & Where The Truck
                  Subscriber, you will be a part of Australia’s largest mobile
                  catering subscription group. You can network, share best
                  practices, gain professional advice and connect with solution
                  providers to help grow your business.
                </p>
              </div>
              <div class="service-bottom">
                <div class="service-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/logo-round.png")}
                    />
                  </div>
                  {/* <h2>Web Design</h2> */}
                  <p className="pl">
                    Professional support and advice from industry experts
                  </p>
                </div>
                <div class="service-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/logo-round.png")}
                    />
                  </div>
                  <p className="pl">
                    Get profitable solutions with the National Buying Group
                  </p>
                </div>
                <div class="service-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/logo-round.png")}
                    />
                  </div>
                  <p className="pl">
                    Access discounted industry tailored products
                  </p>
                </div>
                <div class="service-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/logo-round.png")}
                    />
                  </div>
                  <p className="pl">
                    Opportunity to work at Australia’s largest events, festivals
                    and catering
                  </p>
                </div>
                <div class="service-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/logo-round.png")}
                    />
                  </div>
                  <p className="pl">
                    Australian event, festival and open tender guide
                  </p>
                </div>
                <div class="service-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/logo-round.png")}
                    />
                  </div>
                  <p className="pl">Trade services contacts and discounts</p>
                </div>
                <div class="service-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/logo-round.png")}
                    />
                  </div>
                  <p className="pl">Brand marketing, exposure and promotion</p>
                </div>
                <div class="service-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/logo-round.png")}
                    />
                  </div>
                  <p className="pl">
                    Keep updated with our newsletters, exclusive to subscribers
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="solutions">
            <div class="solutions containerl">
              <div class="solution-top">
                <h1 class="section-title">
                  <span>Solutions</span>
                </h1>
                <h2>Quality Solutions for mobile caterers.</h2>
                <p className="pl">
                  Are you after industry experts advice? AMFVG work with mobile
                  catering partners to assist you today.
                </p>
              </div>
              <div class="solution-bottom">
                <div class="solution-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/maintenanceimg.png")}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  {/* <h2>Electrical & gas</h2> */}
                  <p className="pl">Electrical & gas</p>
                </div>
                <div class="solution-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/electricalimg.png")}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  <p className="pl">Maintenance, repairs & servicing</p>
                </div>
                <div class="solution-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/foodimg.png")}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  <p className="pl">Food & drink suppliers</p>
                </div>
                <div class="solution-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/truckimg.png")}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  <p className="pl">Truck, van & trailer manufacturing</p>
                </div>
                <div class="solution-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/penimg.png")}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  <p className="pl">Set up design & branding</p>
                </div>
                <div class="solution-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/clothimg.png")}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  <p className="pl">Uniform design & manufacturing</p>
                </div>
                <div class="solution-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/packimg.png")}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  <p className="pl">Innovative packaging solutions</p>
                </div>
                <div class="solution-item">
                  <div class="icon">
                    <img
                      className="imgl"
                      src={require("../../assets/img/questionimg.png")}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  <p className="pl">
                    Support on food safety, Streatrader & OH&S
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="projects">
            <div class="projects containerl">
              <div class="projects-header">
                <h1 class="section-title">
                  <span>Subscription Details</span>
                </h1>
              </div>
              <div class="all-projects">
                <div class="project-item">
                  <div class="project-info">
                    <h1>Setup Pack</h1>
                  </div>
                  <div class="project-img">
                    <p className="pl">
                      <div>
                        <p
                          style={{
                            paddingTop: "10px",
                            paddingLeft: "20px",
                          }}
                        >
                          1x Where The Truck Dashboard tablet
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Where The Truck Software
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Web and Mobile optimised app
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Your own menu and menu photos uploaded to the app
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Where The Truck Signage for your truck
                        </p>
                      </div>
                    </p>
                  </div>
                </div>
                <div class="project-item">
                  <div class="project-info">
                    <h1>Ongoing</h1>
                  </div>
                  <div class="project-img">
                    <p className="pl">
                      <div>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Unlimited file openings for alterations made to event
                          catering & pre-booked functions
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          2x file openings for online menu alterations
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Customised ordering button for your website, designed
                          for your brand
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Where The Truck funded customer Loyalty Program
                        </p>
                      </div>
                    </p>
                  </div>
                </div>
                <div class="project-item">
                  <div class="project-info">
                    <h1>Fees</h1>
                  </div>
                  <div class="project-img">
                    <p className="pl">
                      <div>
                        <p
                          style={{
                            color: "#000",
                            paddingTop: "10px",
                            paddingLeft: "20px",
                          }}
                        >
                          Commission on all pre-booked functions
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Transaction fees included in commission fee
                        </p>
                        <p
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          Activation fee will apply in first 12 months.
                        </p>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <a className="sub-btn" href="/subscribe">
                Subscribe Now
              </a>
            </div>
          </section>
          <section id="guide">
            <div className="guide ">
              <div className="guide-header">
                <h1 className="section-title">
                  <span>POS System Prices</span>
                </h1>
                <p className="pl">
                  All members have access to a tablet with WTTPos System.
                  <br />
                  However, you still required your own square terminal and
                  printer
                </p>
              </div>
              <div class="all-guides">
                <div className="price-card">
                  <h3>Purchase Price Guide</h3>
                  <ul>
                    <li>
                      <p>Square Terminal</p>
                      <p>$320</p>
                    </li>
                    <li>
                      <p>Epsom TM30 Printer</p>
                      <p>$590</p>
                    </li>
                    <li>
                      <p>Star Bluetooth Printer</p>
                      <p>$690</p>
                    </li>
                    <li>
                      <p>Cash Register</p>
                      <p>$150</p>
                    </li>
                  </ul>
                </div>
                <div className="price-card">
                  <h3>Rent Price Guide</h3>
                  <ul>
                    <li>
                      <p>Tablet</p>
                      <p>
                        $80 <span className="span">per event</span>
                      </p>
                    </li>
                    <li>
                      <p>Bluetooth / WiFi Printer</p>
                      <p>
                        $80 <span className="span">per event</span>
                      </p>
                    </li>
                    <li>
                      <p>Square Terminal</p>
                      <p>
                        $80 <span className="span">per event</span>
                      </p>
                    </li>
                    <li>
                      <p>Router with SIM Card</p>
                      <p>
                        $240 <span className="span">per event</span>
                      </p>
                    </li>
                    <li>
                      <p>WTT Queue Skipper POS</p>
                      <p>
                        $300 <span className="span">per event</span>
                      </p>
                    </li>
                    <li>
                      <p>Cash Drawer</p>
                      <p>
                        $80 <span className="span">per event</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="pl guide-text">
                In order to be part of our events you must have a fully
                functional WTTPos System (Tablet, Square Terminal, Printer).
                Please see the T&C for all the events.
              </p>
            </div>
          </section>

          <Footer />
          {/* <script src="./app.js"></script> */}
        </div>
      </div>
    </>
  );
});
const Div = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #414345, #232526);
  overflow: hidden;
`;
const Container = styled.div`
  position: relative;
`;
const Form = styled.form`
  position: relative;
  padding: 3rem;
  min-width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 30px #333;
  background: rgba(225, 225, 225, 0.2);
  background-clip: padding-box;
  backdrop-filter: blur(10px);
  z-index: 2;
  background-color: #ef3e6d;
`;
const H2 = styled.h2`
  color: #000;
  padding: 1rem;
  padding-left: 20%;
  text-aligh: center;
  font-size: 2rem;
`;
const P = styled.p`
  color: #000;
  padding: 1rem;
  padding-left: 20%;
  text-aligh: center;
  font-size: 1rem;
`;
const Input = styled.input`
  padding: 10px;
  border-radius: 10px 0 0 10px;
  border: none;
  width: 80%;
  outline: none;
  background: #f3f1ef;
`;

export default OperatorLandingScreen;
