import React, { useState } from "react";
import { Table, DatePicker } from "antd";
import moment from "moment";
import { TextField } from "@material-ui/core";

const EventSchedule = ({ schedule, setSchedule, sameBumpIn, sameBumpOut }) => {
  const handleDateChange = (date, dateString, key, record) => {
    const updatedData = { ...schedule };
    updatedData[record.date][key] = moment(record.bumpIn).set({
      hour: moment(dateString, "HH:mm").hour(),
      minute: moment(dateString, "HH:mm").minute(),
    });
    setSchedule(updatedData);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  if (sameBumpIn === "no") {
    columns.push({
      title: "Bump In",
      dataIndex: "bumpIn",
      key: "bumpIn",
      render: (text, record) => (
        <TextField
          id="datetime-local"
          type="time"
          value={record.bumpIn ? moment(record.bumpIn).format("HH:mm") : ""}
          // inputProps={{
          //   min: moment(this.state.eventStartDate).format(
          //     "HH:mm"
          //   ), // Restrict min date
          //   max: moment(this.state.eventEndDate).format(
          //     "HH:mm"
          //   ), // Restrict max date
          // }}
          onChange={(event) => {
            handleDateChange(null, event.target.value, "bumpIn", record);
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        //   <DatePicker
        //     showTime
        //     value={record.bumpIn ? moment(record.bumpIn) : null}
        //     onChange={(date, dateString) =>
        //       handleDateChange(date, dateString, "bumpIn", record)
        //     }
        //   />
      ),
    });
  }
  if (sameBumpOut === "no") {
    columns.push({
      title: "Bump Out",
      dataIndex: "bumpOut",
      key: "bumpOut",
      render: (text, record) => (
        <TextField
          id="datetime-local"
          type="time"
          value={record.bumpOut ? moment(record.bumpOut).format("HH:mm") : ""}
          // inputProps={{
          //   min: moment(this.state.eventStartDate).format(
          //     "HH:mm"
          //   ), // Restrict min date
          //   max: moment(this.state.eventEndDate).format(
          //     "HH:mm"
          //   ), // Restrict max date
          // }}
          onChange={(event) => {
            handleDateChange(null, event.target.value, "bumpOut", record);
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        //   <DatePicker
        //     showTime
        //     value={record.bumpOut ? moment(record.bumpOut) : null}
        //     onChange={(date, dateString) =>
        //       handleDateChange(date, dateString, "bumpOut", record)
        //     }
        //   />
      ),
    });
  }

  const tableData = Object.keys(schedule).map((date) => ({
    key: date,
    date,
    bumpIn: schedule[date].bumpIn,
    bumpOut: schedule[date].bumpOut,
  }));

  return <Table columns={columns} dataSource={tableData} pagination={false} />;
};

export default EventSchedule;
